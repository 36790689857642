import { Component, OnInit } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ValidatorUtils } from '../../../utils/validator.utils';

@Component({
  selector: 'app-modal-new-group',
  templateUrl: './modal-new-group.component.html',
  styleUrls: ['./modal-new-group.component.css']
})

export class ModalNewGroupComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }

  /* generate response to main component */
  action: Subject<any> = new Subject();

  /* groups variables */
  name: string;
  status: boolean = false;
  CMV: boolean = false;

  /* utils */
  errors: any;

  ngOnInit() {

  }

  /* create new group */
  createGroup() {
    this.errors = this._validateForm();
    let any_error = Object.values(this.errors).find((x) => x == true);
    if (any_error) return;

    this.action.next({
      'name': this.name,
      'status': this.status,
      'CMV': this.CMV
    });
  }

  /* cancel */
  cancelGroupCreation() {
    this.action.next(null);
    this.modalRef.hide();
  }

  /**
   * Validate form
   * @return error object
   */
  private _validateForm(): any {
    let errors = {
      name: false
    }

    let validator = new ValidatorUtils(this.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.name = true;

    return errors;
  }

}

