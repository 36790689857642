import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { GroupsService } from '../../../service/groups.service';
import { ValidatorUtils } from '../../../utils/validator.utils';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupEstablishment } from 'src/app/model/group';
import { MeasurementUnit } from 'src/app/model/constants';

@Component({
  selector: 'app-modal-edit-product',
  templateUrl: './modal-edit-product.component.html',
  styleUrls: ['./modal-edit-product.component.css']
})
export class ModalEditProductComponent{

  constructor(
    public modalRef: MDBModalRef,
    private groupService: GroupsService
  ) { }

  action: Subject<any> = new Subject();

  productForm = {} as any;

  groupList: GroupEstablishment[];

  errorMap = new Map<string, boolean>();

  unitList: MeasurementUnit[];

  editting = false;

  // group selection
  selection = new SelectionModel<any>(false, []);

  //measurement unit selection
  selection2 = new SelectionModel<any>(false, []);

  ngOnInit() {
    if (!this.editting) {
      this.productForm.measurementUnity = this.unitList[0].id;
      this.selection2.select(0)
      this.productForm.groupId = this.groupList[0].group.id;
      this.selection.select(0)
    }
    else {
      let unit_index = this.unitList.findIndex((x) => (x.name == this.productForm.measurementUnity.name));
      this.selection2.select(unit_index)
      let group_index = this.groupList.findIndex((x) => (x.group.id == this.productForm.groupId));
      this.selection.select(group_index)
    }
  }

  createProduct() {
    this.productForm.groupId = this.groupList[this.selection.selected[0]].group.id
    this.productForm.measurementUnity = this.unitList[this.selection2.selected[0]].id
    this.productForm.group = this.groupList[this.selection.selected[0]].group

    this._validateForm();
    if (this.errorMap.size > 0) return;

    this.action.next({
      'name': this.productForm.name,
      'group': this.productForm.group,
      'groupId': this.productForm.groupId,
      'measurementUnity': this.productForm.measurementUnity
    });

    this.modalRef.hide();
  }

  cancel() {
    this.modalRef.hide();
  }

  private _validateForm() {
    this.errorMap = new Map<string, boolean>();

    let validator = new ValidatorUtils(this.productForm.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) this.errorMap.set('name', true);
  }

}
