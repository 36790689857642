import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './service/auth.service';
import { StorageService } from './service/storage.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storageService.getToken()) {
      let split_url = request.url.split('/')
      if (split_url[3] == 'core' && split_url[4] == "csv" || split_url[4] == "csv-save" ) {
        request = this._addTokenWithoutHeader(request, this.storageService.getToken());
      }
      else {
        request = this._addToken(request, this.storageService.getToken());
      }
    }
    else {
      request = this._noTokenRequest(request, this.storageService.getToken());
    }

    return next.handle(request).pipe(catchError((err) => {
      if ([401, 403].indexOf(err.status) !== -1) {
        this.authService.logout();
        location.reload();
      }

      try {
        const error = err.error.non_field_errors || err.error.detail || err.error.email || err.error.message || err.statusTexterr.error.message || err.statusText;
        return throwError(error);
      }
      catch {
        const error = err
        return throwError(error);
      }

    }));
  }

  private _addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `token ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  private _addTokenWithoutHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `token ${token}`,
      }
    });
  }

  private _noTokenRequest(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

}
