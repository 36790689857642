import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.css']
})

export class LoaderSpinnerComponent {

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {

  }

}
