import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MDBModalRef } from 'angular-bootstrap-md';
import { DatesService } from 'src/app/service/dates.service';
import { ProductService } from 'src/app/service/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-download',
  templateUrl: './popup-download.component.html',
  styleUrls: ['./popup-download.component.css']
})

export class PopupDownloadComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private productService: ProductService,
    private datesService: DatesService,
  ) { }

  title;
  screen;
  establishment;

  types = [
    {name: 'Posição de estoque', type: 1},
    {name: 'Histórico do produto', type: 2},
    {name: 'Sugestão de compras', type: 3}
  ]
  type = 1

  year_list = []
  filter_year;
  filter_year_end

  month_list = []
  filter_month;
  filter_month_end

  week_list = []
  filter_week;
  filter_week_end

  group_list = []
  filter_group;

  date_from;
  date_to;
  date_from_week;

  list_product = [];
  list_supplier = [];

  select={
    supplier: null,
    product: null
  }

  page: number = 1;
  search_text;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  ngOnInit(): void {
    this.year_list = this.datesService.getYearsList()
    this.month_list = Object.assign([],this.datesService.month_list)
    this.week_list = Object.assign([], this.datesService.week_list)

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_year = this.datesService.getActualYear()
    this.filter_week = this.datesService.getActualWeekNew()

    this.filter_month_end = this.month_list[this.datesService.getActualMonth()]
    this.filter_year_end = this.datesService.getActualYear()
    this.filter_week_end = this.datesService.getActualWeekNew()

    this.changeDate()

    this.getProducts('')
  }

  getProducts(search){
    if(search == null || search == "") this.select.product = null

    this.productService.getProducts(this.page, this.establishment, search).subscribe(data=> {
      this.list_product = data.results;
    });
  }

  changeDate() {

    let days = this.datesService.changeDate(this.filter_week, this.filter_month, this.filter_year)
    let first_last = this.datesService.lastDayOfMonth('01', this.filter_year, this.filter_month)

    this.date_from_week = days['day1']
    if (this.filter_week == 'Semana 1') {
      this.date_from = first_last['first_day']
    }
    else if (this.filter_week == 'Semana 6') {
      this.date_from = days['day1']
    }
    else if (this.filter_week == 'Semana 5') {
      if (days['day7'].substring(5, 7) != this.filter_month.number) {
        this.date_from = days['day1']
      }
      else {
        this.date_from = days['day1']
      }
    }
    else {
      this.date_from = days['day1']
    }

    this.changeDate2()
  }

  changeDate2() {

    let days_end = this.datesService.changeDate(this.filter_week_end, this.filter_month_end, this.filter_year_end)
    let first_last = this.datesService.lastDayOfMonth('01', this.filter_year_end, this.filter_month_end)

    if (this.filter_week_end == 'Semana 1') {
      this.date_to = days_end['day7']
    }
    else if (this.filter_week_end == 'Semana 6') {
      this.date_to = first_last['last_day']
    }
    else if (this.filter_week_end == 'Semana 5') {
      if (days_end['day7'].substring(5, 7) != this.filter_month_end.number) {
        this.date_to = first_last['last_day']
      }
      else {
        this.date_to = days_end['day7']
      }
    }
    else {
      this.date_to = days_end['day7']
    }
  }

  // Relatorio Posição Estoque - CSV
  getDownloadDischargeCsv() {

    if(this.establishment != null)
      return `${environment.path}core/product-discharge/csv/?establishment=${this.establishment}&date_from=${this.date_from}&date_to=${this.date_to}`
  }

  // Relatorio Posição Estoque - XLSX
  getPositionStock() {

    if(this.establishment != null){
      const path = `${environment.path}core/product-discharge/xlsx/?establishment=${this.establishment}&date_from=${this.date_from}&date_to=${this.date_to}`
      if(this.select.product != null){
        return `${path}&products=${this.select.product.id}`
      }
      else if(this.filter_group != null){
        return `${path}&group=${this.filter_group.group.id}`
      }
      else{
        return path
      }
    }
  }

  // Relatório do Histórico do Produto
  getDownloadHistory() {
    if(this.select.product != null){
      return `${environment.path}core/product_history_report/?product=${this.select.product.id}&date_from=${this.date_from}&date_to=${this.date_to}`
    }
  }

  // Relatório de Compras - Fornecedor
  getPurchaseSupplier() {
    return `${environment.path}core/invoices_supplier_report/?establishment=${this.establishment}&supplier=${this.select.supplier}&date_from=${this.date_from}&date_to=${this.date_to}`
  }

  // Relatório de Compras - Produto
  getPurchaseProduct() {
    if(this.select.product != null){
      return `${environment.path}core/invoices_product_report/?establishment=${this.establishment}&product=${this.select.product.id}&date_from=${this.date_from}&date_to=${this.date_to}`
    }
    else if(this.filter_group != null){
      return `${environment.path}core/invoices_product_report/?establishment=${this.establishment}&group=${this.filter_group.group.id}&date_from=${this.date_from}&date_to=${this.date_to}`
    }
  }

  // Relatório de sugestão de compras
  getDownloadSuggestion(){
    const path = `${environment.path}core/purchase_order_suggestion/?establishment=${this.establishment}&date_from=${this.date_from_week}`
    if(this.select.product != null){
      return `${path}&product=${this.select.product.id}`
    }
    else if(this.filter_group != null){
      return `${path}&group=${this.filter_group.group.id}`
    }
    else{
      return path
    }
  }

}
