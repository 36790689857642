import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css']
})
export class ModalMessageComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
  ) { }

  action: Subject<any> = new Subject();
	text: string = "";
	title: string = "";
	has_button: boolean = false;
	button_text: string = "";
	button_cancel: string = "";

  ngOnInit(): void {
  }

  no(){
    this.action.next('no');
    this.modalRef.hide()
  }

  yes(){
    this.action.next('yes');
    this.modalRef.hide()
  }
}
