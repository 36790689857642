import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { Establishment, Establishments } from '../model/establishment';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class EstablishmentService {

  constructor(private http: HttpClient) { }

  /**
   * Get a list of establishment based on the current page
   * @param page current page number
   * @return list of establishments
   */
  getEstablishments(page: number, search: string): Observable<Establishments> {
    let page_size = environment.page_size;
    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('search', search.toString())
        .set('page_size', page_size.toString())
    };
    return this.http.get<Establishments>(environment.path + "core/establishment/", options)
  }


  /**
   * Get establishment by id
   * @param id establishment's id
   * @return establishment object
   */
  getEstablishmentById(id: number): Observable<Establishment> {
    return this.http.get<Establishment>(`${environment.path}core/establishment/${id}/`);
  }


  /**
   * Delete a establishment based on id
   * @param id establishment's id
   * @return void
   */
  deleteEstablishment(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.path}core/establishment/${id}/`);
  }


  /**
   * Create a establishment
   * @param establishment's params
   * @return just for error tracking
   */
  createEstablishment(establishment_obj: any): Observable<any> {
    const { fantasy_name, company_name, cnpj, public_place, city, uf, accountable, segment, invoice_entry, stock_discharge, cmv_management, shopping_chart } = establishment_obj;

    const body = new HttpParams()
      .set(`fantasy_name`, fantasy_name)
      .set(`company_name`, company_name)
      .set(`cnpj`, cnpj)
      .set(`public_place`, public_place)
      .set(`city`, city)
      .set(`uf`, uf)
      .set(`accountable`, accountable)
      .set(`segment`, segment.toString())
      .set(`invoice_entry`, invoice_entry)
      .set(`stock_discharge`, stock_discharge)
      .set(`cmv_management`, cmv_management)
      .set(`shopping_chart`, shopping_chart)
      .set(`active`, "true");;

    return this.http.post<any>(environment.path + "core/establishment/", body.toString());
  }


  /**
   * Update establishment based on id
   * @param establishment's param
   * @return just for error tracking
   */
  editEstablishment(establishment_obj: any): Observable<void> {
    const { id, fantasy_name, company_name, cnpj, public_place, city, uf, accountable, segment } = establishment_obj;

    const body = new HttpParams()
      .set(`fantasy_name`, fantasy_name)
      .set(`company_name`, company_name)
      .set(`cnpj`, cnpj)
      .set(`public_place`, public_place)
      .set(`city`, city)
      .set(`uf`, uf)
      .set(`accountable`, accountable)
      .set(`segment`, segment);

    return this.http.patch<void>(`${environment.path}core/establishment/${id}/`, body.toString());
  }

  /**
   * Patch establishment active
   * @param active and establishment_id
   * @return establishment
   */
  editEstablishmentStatus(active: boolean, establishment_id: number): Observable<Establishment> {
    const body = new HttpParams()
      .set(`active`, active.toString())

    return this.http.patch<Establishment>(`${environment.path}core/establishment/${establishment_id}/`, body.toString());
  }

  /**
   * Update establishment based on id
   * @param establishment's param
   * @return just for error tracking
   */
  patchEstablishmentModules(establishment_obj: any): Observable<void> {
    const { id, invoice_entry, stock_discharge, cmv_management, shopping_chart, use_goal } = establishment_obj;

    const body = new HttpParams()
      .set(`invoice_entry`, invoice_entry)
      .set(`stock_discharge`, stock_discharge)
      .set(`cmv_management`, cmv_management)
      .set(`shopping_chart`, shopping_chart)
      .set(`use_goal`, use_goal)

    return this.http.patch<void>(`${environment.path}core/establishment/${id}/`, body.toString());
  }

}
