import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { GroupsService } from '../../../service/groups.service';
import { ValidatorUtils } from '../../../utils/validator.utils';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupEstablishment } from 'src/app/model/group';
import { MeasurementUnit } from 'src/app/model/constants';
import { MatTableDataSource } from '@angular/material/table';
import { ProductEntry } from '../modal-new-invoice/modal-new-invoice.component';

@Component({
  selector: 'modal-new-product',
  templateUrl: './modal-new-product.component.html',
  styleUrls: ['./modal-new-product.component.css']
})

export class ModalNewProductComponent {

  constructor(
    public modalRef: MDBModalRef,
    private groupService: GroupsService
  ) { }

  action: Subject<any> = new Subject();

  initial_stock: boolean = false;

  productForm = {
    name: null, measurementUnity: null, group: null, groupId: null, initial_price: null, initial_quantity: null,
  };

  groupList: GroupEstablishment[];

  errorMap = new Map<string, boolean>();

  unitList: MeasurementUnit[];

  editting = false;

  tableSave = false;

  // group selection
  selection = new SelectionModel<any>(false, []);

  //measurement unit selection
  selection2 = new SelectionModel<any>(false, []);

  list_product = []
  cont: number = 0;
  statusEdit: boolean = false;
  num_produc: number = null;
  msg_error: string = '';

  /* -- PAGE 3 -- */
  displayedColumns: string[] = ['name', 'group', 'unity', 'price', 'quantity'];
  data_product = new MatTableDataSource<ProductEntry>();

  ngOnInit() {
    if (!this.editting) {
      this.productForm.measurementUnity = this.unitList[0].id;
      this.selection2.select(0)
      this.productForm.groupId = this.groupList[0].group.id;
      this.selection.select(0)
    }
    else {
      let unit_index = this.unitList.findIndex((x) => (x.name == this.productForm.measurementUnity.name));
      this.selection2.select(unit_index)
      let group_index = this.groupList.findIndex((x) => (x.group.id == this.productForm.groupId));
      this.selection.select(group_index)
    }
  }

  backPage() {
    if((this.list_product != null && this.list_product.length > 0) && !this.tableSave && this.cont == 0){
      this.modalRef.hide()
    }
    else {
      this.cont -= 1;
      if(this.list_product[this.cont] == null){
        this.cont -= 1;
      }
      if (this.list_product[this.cont] != null) {
        this.getViewArray(this.cont)
      }
      this.tableSave = false
    }

  }

  nextPage() {
    this.msg_error = '';

    if(this.list_product[this.cont+1] == null){
      if(this.productForm.name == null || this.productForm.name == ''){
        this.msg_error = 'Por favor, digite o nome do produto.';
      }
      else if(this.groupList[this.selection?.selected[0]]?.group.id == null || this.groupList[this.selection?.selected[0]]?.group.id == undefined){
        this.msg_error = 'Por favor, selecione um grupo.';
      }
      else if(this.unitList[this.selection2?.selected[0]] == null || this.unitList[this.selection2?.selected[0]] == undefined){
        this.msg_error = 'Por favor, selecione uma unid. medida.';
      }
      else if(this.initial_stock && (this.productForm.initial_price == null || this.productForm.initial_price == '')){
        this.msg_error = 'Por favor, digite o custo inicial.';
      }
      else if(this.initial_stock && (this.productForm.initial_quantity == null || this.productForm.initial_quantity == '')){
        this.msg_error = 'Por favor, digite a quantidade inicial.';
      }
      else{
        this.cont += 1;

        if (this.list_product[this.cont] != null) {
          this.getViewArray(this.cont)
        }
        else if(this.data_product[this.cont] == null && this.list_product[this.cont] == null){
          this.addProduct()
        }
        this.tableSave = false
      }
    }


  }

  finish(){
    this.msg_error = '';

    if(this.list_product[this.cont+1] == null){
      if(this.productForm.name == null || this.productForm.name == ''){
        this.msg_error = 'Por favor, digite o nome do produto.';
      }
      else if(this.groupList[this.selection?.selected[0]]?.group.id == null || this.groupList[this.selection?.selected[0]]?.group.id == undefined){
        this.msg_error = 'Por favor, selecione um grupo.';
      }
      else if(this.unitList[this.selection2?.selected[0]] == null || this.unitList[this.selection2?.selected[0]] == undefined){
        this.msg_error = 'Por favor, selecione uma unid. medida.';
      }
      else if(this.initial_stock && (this.productForm.initial_price == null || this.productForm.initial_price == '')){
        this.msg_error = 'Por favor, digite o custo inicial.';
      }
      else if(this.initial_stock && (this.productForm.initial_quantity == null || this.productForm.initial_quantity == '')){
        this.msg_error = 'Por favor, digite a quantidade inicial.';
      }
      else{
        this.cont += 1;
        this.addProduct()

        this.tableSave = true;
        this.cont = this.list_product.length;
      }
    }

    // if(this.list_product[this.cont+1] == null && this.productForm.name != null && this.productForm.group != null && this.productForm.groupId != null &&
    //   this.productForm.measurementUnity != null && this.productForm.initial_price != null && this.productForm.initial_quantity != null){
    //     this.cont += 1;
    //     this.addProduct()
    // }

    // this.tableSave = true;
    // this.cont = this.list_product.length;

  }

  addProduct(){
    if(this.data_product.data[this.cont-1] == null && this.list_product[this.cont-1] == null){
      this._validateForm();
      if (this.errorMap.size > 0) return;

      this.productForm.groupId = this.groupList[this.selection?.selected[0]]?.group.id
      this.productForm.measurementUnity = this.unitList[this.selection2.selected[0]]
      this.productForm.group = this.groupList[this.selection?.selected[0]]?.group

      this.list_product.push({
        name:this.productForm.name,
        group: this.productForm.group,
        groupId: this.productForm.groupId,
        measurementUnity: this.productForm.measurementUnity,
        initial_price: this.productForm.initial_price,
        initial_quantity: this.productForm.initial_quantity,
      })

      this.data_product.data = this.list_product
    }

    this.productForm.name = null
    this.productForm.initial_price = 0
    this.productForm.initial_quantity = 0
    this.productForm.measurementUnity = this.unitList[0];
    this.selection2.select(0)
    this.productForm.groupId = this.groupList[0].group.id;
    this.selection.select(0)
  }

  getViewArray(position) {
    this.productForm.name = null
    this.productForm.initial_price = 0
    this.productForm.initial_quantity = 0
    this.productForm.measurementUnity = this.unitList[0];
    this.selection2.select(0)
    this.productForm.groupId = this.groupList[0].group.id;
    this.selection.select(0)

    this.productForm.name = this.list_product[position].name;
    this.productForm.initial_price = this.list_product[position].initial_price;
    this.productForm.initial_quantity = this.list_product[position].initial_quantity;
    this.productForm.measurementUnity = this.list_product[position].measurementUnity;
    this.productForm.groupId = this.list_product[position].groupId;

    let unit_index = this.unitList.findIndex((x) => (x.id == this.productForm.measurementUnity.id));
    this.selection2.select(unit_index)
    let group_index = this.groupList.findIndex((x) => (x.group.id == this.productForm.groupId));
    this.selection.select(group_index)
  }

  editProduct(data, num) {
    this.num_produc = num

    this.productForm.name = data.name;
    this.productForm.group = data.group;
    this.productForm.groupId = data.groupId;
    this.productForm.initial_price = data.initial_price;
    this.productForm.initial_quantity = data.initial_quantity;
    this.productForm.measurementUnity = data.measurementUnity;
    this.productForm.groupId = data.groupId;

    let unit_index = this.unitList.findIndex((x) => (x.id == this.productForm.measurementUnity.id));
    this.selection2.select(unit_index)
    let group_index = this.groupList.findIndex((x) => (x.group.id == this.productForm.groupId));
    this.selection.select(group_index)

    this.tableSave = false;
    this.statusEdit = true;
  }

  removeProduct() {
    this.list_product.splice(this.num_produc, 1);
    this.cont = this.list_product.length;
    this.statusEdit = false;
    this.tableSave = true;
  }

  saveEdit() {
    this._validateForm();
    if (this.errorMap.size > 0) return;

    this.productForm.groupId = this.groupList[this.selection.selected[0]].group.id
    this.productForm.measurementUnity = this.unitList[this.selection2.selected[0]]
    this.productForm.group = this.groupList[this.selection.selected[0]].group

    this.list_product.push({
      name:this.productForm.name,
      group: this.productForm.group,
      groupId: this.productForm.groupId,
      measurementUnity: this.productForm.measurementUnity,
      initial_price: this.productForm.initial_price,
      initial_quantity: this.productForm.initial_quantity,
    })

    this.data_product.data = this.list_product

    this.list_product.splice(this.num_produc, 1);
    this.statusEdit = false;
    this.tableSave = true;
  }

  createProduct() {
    // this._validateForm();
    // if (this.errorMap.size > 0) return;

    this.action.next({
      'list': this.list_product,
    });

    this.modalRef.hide();
  }

  cancel() {
    this.modalRef.hide();
  }

  private _validateForm() {
    this.errorMap = new Map<string, boolean>();

    let validator = new ValidatorUtils(this.productForm.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) this.errorMap.set('name', true);

    if(this.initial_stock){
      validator = new ValidatorUtils(this.productForm.initial_price);
      validator.applyRule('not-empty');
      if (!validator.validate()) this.errorMap.set('price', true);

      validator = new ValidatorUtils(this.productForm.initial_quantity);
      validator.applyRule('not-empty');
      if (!validator.validate()) this.errorMap.set('quantity', true);
    }
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

}

export interface Product {
	name: string;
	group: Group;
	groupId: number;
	measurementUnity: any;
  initial_price: string;
  initial_quantity: number
}

export interface Group {
	id: number,
	name: string,
	defaut: boolean,
	created_at: string
}
