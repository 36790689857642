import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'modal-see-products',
  templateUrl: './modal-see-products.component.html',
  styleUrls: ['./modal-see-products.component.css']
})

export class ModalSeeProductsComponent {

  constructor(public modalRef: MDBModalRef) { }

  action: Subject<any> = new Subject();

  data_product = new MatTableDataSource<Product>();
  displayedColumns: string[] = ['name', 'group', 'measurementUnity', 'saldo', 'quantity'];
  source;

  is_example: boolean = false;

  ngOnInit() {
    this.data_product.data = this.source;
  }

  save() {
    this.action.next('save')
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

}
