import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class StockService {

  constructor(private http: HttpClient) { }


  getProductDischarge(
    establishment: number, date_from: string, date_to: string, group: number | null, cost_center, product: number | null
  ): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
    };

    if (group != null) {
      options.params = options.params.append('group', group.toString())
    }

    if (cost_center != null && cost_center != "") {
      options.params = options.params.append('cost_center', cost_center)
    }

    if (product != null) {
      options.params = options.params.append('product', product.toString())
    }

    return this.http.get<any>(environment.path + "core/product-discharge/", options);
    //core/product-discharge/?establishment=18&date_from=2019-10-10&date_to=2020-07-10&group=26
  }

  getGeneralDischarge(establishment: number, date_from: string, date_to: string, group: number, cost_center): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
        .set('group', group.toString())
    };

    if (cost_center != null && cost_center != "") {
      options.params = options.params.append('cost_center', cost_center)
    }

    return this.http.get<any>(environment.path + "core/general-discharge-new/", options);
  }

  addOrEditDischarge(establishment: number, product, date, quantity: number, cost_center, is_adjustment): Observable<any> {

    let body = new HttpParams()
      .set('establishment', establishment.toString())
      .set('product', product.toString())
      .set('quantity', quantity.toString())
      .set('is_adjustment', is_adjustment.toString())
      .set('date', date.toString())

    if (cost_center != null && cost_center != "") {
      body = body.append('cost_center', cost_center)
    }
    else {
      body = body.append('cost_center', "1")
    }

    return this.http.post<any>(environment.path + "core/add-or-edit-discharge/", body.toString());
  }

  postDischarge(establishment: number, product, date, quantity: number, cost_center, is_adjustment): Observable<any> {
    let body = new HttpParams()
      .set('establishment', establishment.toString())
      .set('product', product.toString())
      .set('quantity', quantity.toString())
      .set('is_adjustment', is_adjustment.toString())
      .set('date', date.toString())

    if (cost_center != null && cost_center != "") {
      body = body.append('cost_center', cost_center)
    }

    return this.http.post<any>(environment.path + "core/discharge/", body.toString());
  }

  patchDischarge(id: number, quantity: number): Observable<any> {
    const body = new HttpParams()
      .set('quantity', quantity.toString())

    return this.http.patch<any>(environment.path + `core/discharge/${id}/`, body.toString());
  }

  getDischarge(id: number): Observable<any> {
    return this.http.get<any>(environment.path + `core/discharge/${id}/`);
  }

  postKgSold(total_kg: any): Observable<any> {
    const body = new HttpParams()
      .set('total_kg_sold', JSON.stringify(total_kg))

    return this.http.post<any>(environment.path + "core/total-kg-sold/", body.toString());
  }

}
