import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ProductsComponent } from './pages/products/products.component';
import { HomeComponent } from './pages/home/home.component';
import { InvoiceEntryComponent } from './pages/invoice-entry/invoice-entry.component';
import { LowStockComponent } from './pages/low-stock/low-stock.component';
import { GraphicsShoppingComponent } from './pages/graphics-shopping/graphics-shopping.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { UsersComponent } from './pages/users/users.component';
import { EstablishmentsComponent } from './pages/establishments/establishments.component';
import { CMVManagementComponent } from './pages/cmv-management/cmv-management.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { CostCenterComponent } from './pages/cost-center/cost-center.component';

import { AuthGuard } from './auth.guard';
import { PurchaseSuggestionComponent } from './pages/purchase-suggestion/purchase-suggestion.component';
import { HistoricalChartComponent } from './pages/historical-chart/historical-chart.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomepageComponent, canActivate: [AuthGuard] },
  {
    path: 'main',
    component: SidenavComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      {
        path: 'products', component: ProductsComponent,
        canActivate: [AuthGuard], data: { page: 'Products' }
      },
      {
        path: 'invoice-entry', component: InvoiceEntryComponent,
        canActivate: [AuthGuard], data: { page: 'Invoice' }
      },
      {
        path: 'low-stock', component: LowStockComponent,
        canActivate: [AuthGuard], data: { page: 'Stock' }
      },
      {
        path: 'purchase-suggestion', component: PurchaseSuggestionComponent,
        canActivate: [AuthGuard], data: { page: 'Suggestion' }
      },
      {
        path: 'graphics-shopping', component: GraphicsShoppingComponent,
        canActivate: [AuthGuard], data: { page: 'Charts' }
      },
      {
        path: 'goals', component: GoalsComponent,
        canActivate: [AuthGuard], data: { page: 'Goals' }
      },
      {
        path: 'users', component: UsersComponent,
        canActivate: [AuthGuard], data: { page: 'Users' }
      },
      {
        path: 'cost-center', component: CostCenterComponent,
        canActivate: [AuthGuard], data: { page: 'Users' }
      },
      {
        path: 'establishments', component: EstablishmentsComponent,
        canActivate: [AuthGuard], data: { roles: ['Admin'] }
      },
      {
        path: 'establishment/:id', component: EstablishmentComponent,
        canActivate: [AuthGuard], data: { roles: ['Admin'] }
      },
      {
        path: 'cmv-management', component: CMVManagementComponent,
        canActivate: [AuthGuard], data: { page: 'Cmv' }
      },
      {
        path: 'historical-chart', component: HistoricalChartComponent,
      },
      { path: '**', redirectTo: '/' }
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const routingComponents = [
  HomepageComponent, SidenavComponent, HomeComponent, ProductsComponent, InvoiceEntryComponent,
  LowStockComponent, PurchaseSuggestionComponent, GraphicsShoppingComponent, GoalsComponent, UsersComponent,
  EstablishmentsComponent, CMVManagementComponent
];
