import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from '@angular/http';
import { NgxMaskModule } from 'ngx-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

//telas
import { AppComponent } from './app.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { LoginComponent } from './pages/login/login.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { InvoiceEntryComponent } from './pages/invoice-entry/invoice-entry.component';
import { LowStockComponent } from './pages/low-stock/low-stock.component';
import { GraphicsShoppingComponent } from './pages/graphics-shopping/graphics-shopping.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { UsersComponent } from './pages/users/users.component';
import { EstablishmentsComponent } from './pages/establishments/establishments.component';
import { CMVManagementComponent } from './pages/cmv-management/cmv-management.component';
import { SmallGraphicComponent } from './pages/small-graphic/small-graphic.component';
import { CostCenterComponent } from './pages/cost-center/cost-center.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { LoaderSpinnerComponent } from './pages/alerts/loader-spinner/loader-spinner.component';
import { AlertComponent } from './pages/alerts/alert/alert.component';
import { AlertConfirmationComponent } from './pages/alerts/alert-confirmation/alert-confirmation.component';
import { StockDetailComponent } from './pages/low-stock/stock-detail/stock-detail.component';
import { StockAdjustmentComponent } from './pages/low-stock/stock-adjustment/stock-adjustment.component';
import { RevenueDetailComponent } from './pages/cmv-management/revenue-detail/revenue-detail.component';
import { PurchaseSuggestionComponent } from './pages/purchase-suggestion/purchase-suggestion.component';
import { HistoricalChartComponent } from './pages/historical-chart/historical-chart.component';

// modals / popups
import { ModalErrorComponent } from './components/modals/modal-error/modal-error.component';
import { ModalNewProductComponent } from './components/modals/modal-new-product/modal-new-product.component';
import { ModalNewInvoiceComponent } from './components/modals/modal-new-invoice/modal-new-invoice.component';
import { ModalNewUserComponent } from './components/modals/modal-new-user/modal-new-user.component';
import { ModalNewEstablishmentComponent } from './components/modals/modal-new-establishment/modal-new-establishment.component';
import { ModalEditEstablishmentComponent } from './components/modals/modal-edit-establishment/modal-edit-establishment.component';
import { ModalEditInvoiceComponent } from './components/modals/modal-edit-invoice/modal-edit-invoice.component';
import { ModalNewCostCenterComponent } from './components/modals/modal-new-cost-center/modal-new-cost-center.component';
import { ModalSeeProductsComponent } from './components/modals/modal-see-products/modal-see-products.component';
import { PopupDownloadComponent } from './components/modals/popup-download/popup-download.component';
import { ModalEditProductComponent } from './components/modals/modal-edit-product/modal-edit-product.component';
import { ModalMessageComponent } from './components/modals/modal-message/modal-message.component';
import { ModalNewGroupComponent } from './components/modals/modal-new-group/modal-new-group.component';

import { HttpRequestInterceptor } from './http-request.interceptor';
import { StorageService } from './service/storage.service';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LoginComponent,
    SidenavComponent,
    ProductsComponent,
    HomeComponent,
    InvoiceEntryComponent,
    LowStockComponent,
    ModalErrorComponent,
    ModalNewProductComponent,
    ModalNewInvoiceComponent,
    GraphicsShoppingComponent,
    GoalsComponent,
    UsersComponent,
    ModalNewUserComponent,
    EstablishmentsComponent,
    ModalNewEstablishmentComponent,
    CMVManagementComponent,
    EstablishmentComponent,
    ModalNewGroupComponent,
    ModalEditEstablishmentComponent,
    LoaderSpinnerComponent,
    AlertConfirmationComponent,
    AlertComponent,
    ModalEditInvoiceComponent,
    SmallGraphicComponent,
    ModalSeeProductsComponent,
    PopupDownloadComponent,
    CostCenterComponent,
    ModalNewCostCenterComponent,
    ModalEditProductComponent,
    ModalMessageComponent,
    StockDetailComponent,
    StockAdjustmentComponent,
    RevenueDetailComponent,
    PurchaseSuggestionComponent,
    HistoricalChartComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatToolbarModule,
    MatFormFieldModule,
    FormsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    BrowserAnimationsModule,
    ChartsModule,
    MatDialogModule,
    HttpClientModule,
    HttpModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  entryComponents: [
    ModalErrorComponent,
    ModalNewProductComponent,
    ModalNewInvoiceComponent,
    ModalNewUserComponent,
    ModalNewEstablishmentComponent,
    ModalNewGroupComponent,
    ModalEditEstablishmentComponent,
    LoaderSpinnerComponent,
    AlertConfirmationComponent,
    AlertComponent,
    ModalSeeProductsComponent,
    StockDetailComponent,
    StockAdjustmentComponent,
    RevenueDetailComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: "pt" },
    StorageService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
