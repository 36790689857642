import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import getWeekOfMonth from 'date-fns/getWeekOfMonth';
@Injectable({
  providedIn: 'root'
})

export class DatesService {

  constructor(private datePipe: DatePipe) { }

  month_list = [
    { number: '01', num: 1, name: 'Janeiro' },
    { number: '02', num: 2, name: 'Fevereiro' },
    { number: '03', num: 3, name: 'Março' },
    { number: '04', num: 4, name: 'Abril' },
    { number: '05', num: 5, name: 'Maio' },
    { number: '06', num: 6, name: 'Junho' },
    { number: '07', num: 7, name: 'Julho' },
    { number: '08', num: 8, name: 'Agosto' },
    { number: '09', num: 9, name: 'Setembro' },
    { number: '10', num: 10, name: 'Outubro' },
    { number: '11', num: 11, name: 'Novembro' },
    { number: '12', num: 12, name: 'Dezembro' }
  ]

  week_list: string[] = ['Todas as semanas', 'Semana 1', 'Semana 2', 'Semana 3', 'Semana 4', 'Semana 5', 'Semana 6']
  week_list_all: string[] = ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4', 'Semana 5', 'Semana 6', 'Todas as semanas']

  getMonthRange(monthName: string, year: number) {
    console.log(monthName, year);

    // Mapeia os nomes dos meses para seus números correspondentes
    const monthNames = [
      "janeiro", "fevereiro", "março", "abril",
      "maio", "junho", "julho", "agosto",
      "setembro", "outubro", "novembro", "dezembro"
    ];

    const monthIndex = monthNames.findIndex(name => name.toLowerCase() === monthName.toLowerCase());

    if (monthIndex === -1) {
      throw new Error("Nome do mês inválido");
    }

    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);

    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);

    return {
      dateFrom: formattedStartDate,
      dateTo: formattedEndDate
    };
  }

  getYearsList() {
    var aux = new Date().getFullYear()
    var year_list = []
    for (let i = aux; i >= 2020; i--) {
      year_list.push(i)
    }
    return year_list;
  }

  getActualDay() {
    var date = new Date()
    return date.getDay()
  }

  getActualMonth() {
    var date = new Date()
    return date.getMonth()
  }

  getActualYear() {
    var date = new Date()
    return date.getFullYear()
  }

  getActualWeekNew() {
    var d = new Date();
    var day = d.getDate();
    let inicial_date = d.getFullYear()+"-"+(d.getMonth()+1)+" 12:00";
    var data = new Date(inicial_date)
    
    var weekAttual = data.getDay()
    let week:any = [];
    let weekCount:number = 1;
    let dayClosed = 0
    let firstWeek : Boolean = true

    // changed if for Sunday from 0 to 7 to facilitate logic
    if(weekAttual == 0){
      weekAttual = 7
    }

    // index <= 37 = because to close de weeek in the extreme case, the inicial could be 31, so plus 6 days to finish the week
    for (let index = 1; index <= 37; index++) {
      if(weekAttual == 7){
        if(dayClosed+1 <= 31){
          if(firstWeek){
            week.push([1,index, `Semana ${weekCount}`,1]);
            firstWeek = false
          }else{
            week.push([dayClosed+1,dayClosed+7, `Semana ${weekCount}`,weekCount]);
          }
        }
        weekCount += 1
        dayClosed = index
        weekAttual = 0
      }
      weekAttual += 1      
    }

    week.forEach(e => {
      if(day >= e[0] && day <= e[1]){
        week = e[3];
      }
    });

    return this.week_list[week];
    
  }

  getActualWeek() {
    let week = "";
    var d = new Date();


    var day = d.getDate();
    var year = d.getFullYear();

    let DataInicial = year+"-"+(d.getMonth()+1)+"-1 00:00";
    let d2 = new Date(DataInicial);
    let diaAteFimSemana = 7 - d2.getDay()+1;
    let Semana:any = [];

    Semana.push([0,diaAteFimSemana,'Semana1',1]);
    Semana.push([diaAteFimSemana+1,diaAteFimSemana+7,'Semana2',2]);
    Semana.push([diaAteFimSemana+8,diaAteFimSemana+14,'Semana3',3]);
    Semana.push([diaAteFimSemana+15,diaAteFimSemana+21,'Semana4',4]);
    Semana.push([diaAteFimSemana+22,diaAteFimSemana+28,'Semana5',5]);
    Semana.push([diaAteFimSemana+29, diaAteFimSemana+35,'Semana6',6]);


    Semana.forEach(e => {
      if(day >= e[0] && day <= e[1]){
        week = e[3];
      }
    });

    return this.week_list[week];
  }

  getWeeksOptions(month, year) {
    var day = new Date(year, month.num, 0);
    const count_weeks = getWeekOfMonth(new Date(day), { weekStartsOn: 1 })

    return this.week_list.slice(0, (count_weeks + 1))
  }

  // getWeeksOptions(month, year) {
  //   let days_week5 = this.setDays('29', 28, month, year, 5)
  //   let month_day1 = parseInt(days_week5['day1'].substring(6, 8))
  //   let month_day7 = parseInt(days_week5['day7'].substring(6, 8))

  //   if (month_day1 != month.num) { // first day of week 5 is already next month, so end at week 4
  //     return this.week_list.slice(0, 5)
  //   }

  //   if (month_day7 != month.num) { // last day of week 5 is already next month, so end at week 5
  //     return this.week_list.slice(0, 6)
  //   }

  //   let first_last = this.lastDayOfMonth('01', year, month)
  //   let day_day7 = parseInt(days_week5['day7'].slice(-2))
  //   let day_last = parseInt(first_last['last_day'].slice(-2))

  //   if (day_day7 == day_last) { // last day of week 5 is last day of month, so end at week 5
  //     return this.week_list.slice(0, 6)
  //   }

  //   return this.week_list
  // }


  changeDate(filter_week, filter_month, filter_year) {
    let days;
    if (filter_week == "Semana 1") {
      days = this.setDays('01', 0, filter_month, filter_year)
    }
    else if (filter_week == "Semana 2") {
      days = this.setDays('08', 7, filter_month, filter_year)
    }
    else if (filter_week == "Semana 3") {
      days = this.setDays('15', 14, filter_month, filter_year)
    }
    else if (filter_week == "Semana 4") {
      days = this.setDays('22', 21, filter_month, filter_year)
    }
    else if (filter_week == "Semana 5") {
      days = this.setDays('29', 28, filter_month, filter_year, 5)
    }
    else if (filter_week == "Semana 6") {
      let month = filter_month.num == 12 ? this.month_list[0] : this.month_list[filter_month.num]
      let year = filter_month.num == 12 ? filter_year + 1 : filter_year
      days = this.setDays('01', 0, month, year)
    }
    else if (filter_week == '' || filter_week == 'Todas as semanas') {
      let aux = this.lastDayOfMonth('01', filter_year, filter_month)
      days = { day1: aux.first_day, day7: aux.last_day }
    }
    return days;
  }


  setDays(day, day_int, filter_month, filter_year, week = null) {
    const date = new Date(`${filter_year}/${filter_month.number}/${day}`)

    // --> D/S/T/Q/Q/S/S
    // var day1 = this.setDay(date.getDay() - 1 - day_int, day, filter_month, filter_year, week)
    // var day2 = this.setDay(date.getDay() - 2 - day_int, day, filter_month, filter_year, week)
    // var day3 = this.setDay(date.getDay() - 3 - day_int, day, filter_month, filter_year, week)
    // var day4 = this.setDay(date.getDay() - 4 - day_int, day, filter_month, filter_year, week)
    // var day5 = this.setDay(date.getDay() - 5 - day_int, day, filter_month, filter_year, week)
    // var day6 = this.setDay(date.getDay() - 6 - day_int, day, filter_month, filter_year, week)
    // var day7 = this.setDay(date.getDay() - 7 - day_int, day, filter_month, filter_year, week)

    let days_week = this.getGetDaysToSet(date.getDay())

    // --> S/T/Q/Q/S/S/D
    var day1 = this.setDay(days_week[0] + day_int, day, filter_month, filter_year, week)
    var day2 = this.setDay(days_week[1] + day_int, day, filter_month, filter_year, week)
    var day3 = this.setDay(days_week[2] + day_int, day, filter_month, filter_year, week)
    var day4 = this.setDay(days_week[3] + day_int, day, filter_month, filter_year, week)
    var day5 = this.setDay(days_week[4] + day_int, day, filter_month, filter_year, week)
    var day6 = this.setDay(days_week[5] + day_int, day, filter_month, filter_year, week)
    var day7 = this.setDay(days_week[6] + day_int, day, filter_month, filter_year, week)

    return { day1: day1, day2: day2, day3: day3, day4: day4, day5: day5, day6: day6, day7: day7 }
  }

  getGetDaysToSet(day_week) {
    if (day_week == 0) { //dom
      return [-5, -4, -3, -2, -1, 0, 1]
    }
    else if (day_week == 1) { //seg
      return [1, 2, 3, 4, 5, 6, 7]
    }
    else if (day_week == 2) { //ter
      return [0, 1, 2, 3, 4, 5, 6]
    }
    else if (day_week == 3) { //qua
      return [-1, 0, 1, 2, 3, 4, 5]
    }
    else if (day_week == 4) { //qui
      return [-2, -1, 0, 1, 2, 3, 4]
    }
    else if (day_week == 5) { //sex
      return [-3, -2, -1, 0, 1, 2, 3]
    }
    else if (day_week == 6) { //sab
      return [-4, -3, -2, -1, 0, 1, 2]
    }
  }


  setDay(day_week, day, filter_month, filter_year, week = null) {
    let date = new Date(`${filter_year}/${filter_month.number}/${day}`)

    if (week == 5 && date.getDate() < 6) {
      date.setMonth(date.getMonth() - 1)
    }

    date.setDate(day_week)

    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }


  lastDayOfMonth(day, filter_year, filter_month) {
    var first_day = `${filter_year}-${filter_month.number}-${day}`
    let date2

    if (filter_month.num != 12) {
      date2 = new Date(`${filter_year}/${filter_month.num + 1}/${day}`)
    } else {
      date2 = new Date(`${filter_year + 1}/01/${day}`)
    }

    date2.setDate(0)
    var last_day = this.datePipe.transform(date2, 'yyyy-MM-dd')

    return { first_day: first_day, last_day: last_day }
  }


  weeksOnMonth(year, month) {
    // Crie uma instância de Date para o primeiro dia do mês
    const firtDay = new Date(year, month - 1, 1);

    // Obtenha o último dia do mês
    const lastDay = new Date(year, month, 0);

    // Calcule o número de dias no mês
    const daysMonth = lastDay.getDate();

    // Calcule o número de weeks
    const weeks = Math.ceil((daysMonth + firtDay.getDay()) / 7);

    return weeks;
  }

}
