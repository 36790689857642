import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNewEstablishmentComponent } from '../../components/modals/modal-new-establishment/modal-new-establishment.component';
import { AlertService } from '../../service/alert.service';
import { EstablishmentService } from '../../service/establishment.service';
import { Establishment, Establishments } from '../../model/establishment';
import { GroupsService } from '../../service/groups.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'establishments',
  templateUrl: './establishments.component.html',
  styleUrls: ['./establishments.component.css']
})

export class EstablishmentsComponent {

  constructor(
    private modalService: MDBModalService,
    private alertService: AlertService,
    private establishmentService: EstablishmentService,
    private groupsService: GroupsService
  ) { }

  /* reference to modal components */
  modalRef: any;

  /* table related variables */
  displayed_cols: string[] = ['name', 'social_reason', 'cnpj', 'responsible', 'place', 'city', 'uf', 'segment', 'status', 'option'];
  data_source = new MatTableDataSource<Establishment>();

  /* pagination related variables */
  current_page = 1;
  max_page_size = environment.page_size;
  total_entries: number;
  establishments_get_response: Establishments;

  search: string;
  is_searching: boolean = false;


  ngOnInit() {
    this._loadEstablishments(this.current_page, "");
  }

  /* Change status */
  changeStatus(active, establishment_id) {
    this.establishmentService.editEstablishmentStatus(active, establishment_id).subscribe(
      (data) => {
      },
      (err) => {
        this.alertService.alert('Erro!', 'Erro ao mudar status do estabelecimento');
        console.error(err);
      }
    );
  }

  /* Change to the next page */
  nextPage() {
    if (this.establishments_get_response.next != null) {
      if (this.current_page * this.max_page_size < this.total_entries)
        this.current_page++;

      if (this.is_searching)
        this._loadEstablishments(this.current_page, this.search);
      else
        this._loadEstablishments(this.current_page, "");
    }
  }


  /* Change to the previous page */
  prevPage() {
    if (this.establishments_get_response.previous != null) {
      this.current_page--;
      if (this.current_page < 0) this.current_page = 0;

      if (this.is_searching)
        this._loadEstablishments(this.current_page, this.search);
      else
        this._loadEstablishments(this.current_page, "");
    }
  }

  /* Handle modal to create new establishment */
  createEstablishment() {
    this.modalRef = this.modalService.show(ModalNewEstablishmentComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-lg',
      containerClass: '',
      animated: false
    })

    this.modalRef.content.action.subscribe((res: any) => {
      if (res) {
        this._createEstablishment(res);
      }
    });
  }

  /* Delete establishment */
  deleteEstablishment(id: number) {
    this.alertService.alertConfirmation('Deletando...', 'Deseja realmente deletar?').subscribe((res) => {
      if (res) {
        this._deleteEstablishment(id);
      }
    });

  }

  /**
   * Create establishment
   * @param establishmentObj { fanatasy_name, company_name, cnpj, public_place, city, uf, accountable, segment }
   * @return void
   */
  private _createEstablishment(res): void {
    this.alertService.loaderSpinnerOpen();
    this.establishmentService.createEstablishment({
      'fantasy_name': res.fantasy_name,
      'company_name': res.company_name,
      'cnpj': res.cnpj,
      'public_place': res.public_place,
      'city': res.city,
      'uf': res.uf,
      'accountable': res.accountable,
      'segment': res.segment,
      'invoice_entry': res.invoice_entry,
      'stock_discharge': res.stock_discharge,
      'cmv_management': res.cmv_management,
      'shopping_chart': res.shopping_chart,
    }).subscribe(data => {
      this.alertService.loaderSpinnerClose();
      this.alertService.alert('Criado!', 'Estabelecimento criado com sucesso', 2000);
      setTimeout(() => {
        this._loadEstablishments(1, "");
      }, 2400);
    }, error => {
      this.alertService.loaderSpinnerClose();
      this.alertService.alert('Erro!', 'Erro ao criar estabelecimento', 2000);
      console.error(error);
    });
  }

  /**
   * Delete establishment
   * @param id establishment's id
   * @return void
   */
  private _deleteEstablishment(id: number): void {
    this.alertService.loaderSpinnerOpen();
    this.establishmentService.deleteEstablishment(id).subscribe(
      (data) => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Deletado!', 'Estabelecimento deletado com sucesso.');
        this._updateDataSource({ 'id': id });
        this._updatePagination(this.total_entries - 1);
      },
      (err) => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Erro!', 'Erro ao deletar');
        console.error(err);
      }
    );

  }

  /**
   * Get a list of estblishment based on the page
   * @param page the current page to be shown
   * return void
   */
  private _loadEstablishments(page: number, search: string): void {
    this.alertService.loaderSpinnerOpen();
    this.establishmentService.getEstablishments(page, search).subscribe(
      (data) => {
        this.data_source.data = data.results
        this.establishments_get_response = data;
        this._updatePagination(data.count);

        this.alertService.loaderSpinnerClose();
      },
      (err) => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Erro!', 'Erro ao carregar estabelecimentos');
        console.error(err);
      }
    );
  }

  /**
   * Update material table data source
   * @param Object table columns
   * @return void
   */
  private _updateDataSource({
    id,
    accountable = '',
    active = false,
    city = '',
    cnpj = '',
    company_name = '',
    fantasy_name = '',
    public_place = '',
    segment = 0,
    uf = '',
    action = 'delete'
  }): void {
    let el_index = this.data_source.data.findIndex((x) => (x.id == id));
    if (action == 'delete') {
      this.data_source.data.splice(el_index, 1);
      this.data_source = new MatTableDataSource<any>(this.data_source.data);
    }
    else {
      this.data_source.data[el_index].accountable = accountable;
      this.data_source.data[el_index].city = city;
      this.data_source.data[el_index].company_name = company_name;
      this.data_source.data[el_index].fantasy_name = fantasy_name;
      this.data_source.data[el_index].public_place = public_place;
      this.data_source.data[el_index].cnpj = cnpj;
      this.data_source.data[el_index].segment = segment;
      this.data_source.data[el_index].uf = uf;
    }
  }


  /**
   * Update pagination control
   * @param total_entries the number of entries to be shown
   * @return void
   */
  private _updatePagination(total_entries: number): void {
    this.total_entries = total_entries;
  }

  searchEstablishments() {
    if (this.search == "" || this.search == null) {
      this.is_searching = false;
      this._loadEstablishments(this.current_page, "")
    }
    else {
      this.is_searching = true;
      this._loadEstablishments(this.current_page, this.search)
    }
  }

}
