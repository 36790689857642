import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ValidatorUtils } from '../../../utils/validator.utils';

@Component({
  selector: 'modal-edit-establishment',
  templateUrl: './modal-edit-establishment.component.html',
  styleUrls: ['./modal-edit-establishment.component.css']
})

export class ModalEditEstablishmentComponent {

  constructor(public modalRef: MDBModalRef) { }

  /* used to generate reponse to the main component */
  action: Subject<any> = new Subject();

  /* establishment variables */
  fantasy_name: string;
  accountable: string;
  company_name: string;
  public_place: string;
  cnpj: string;
  city: string;
  uf: string;

  /* utils */
  cnpj_mask = '00.000.000/0000-00';
  errors: any;

  ngOnInit() {

  }

  /* handles user response */
  userResponse(response: boolean) {
    if (response) {
      this.errors = this._validateForm();
      let any_error = Object.values(this.errors).find((x) => x == true);
      if (any_error) return

      this.action.next({
        'accountable': this.accountable,
        'city': this.city,
        'cnpj': this.cnpj,
        'company_name': this.company_name,
        'fantasy_name': this.fantasy_name,
        'public_place': this.public_place,
        'uf': this.uf
      });
    }
    else {
      this.action.next(null);
    }
  }

  /**
   * Validate form
   * @return an error object
   */
  private _validateForm(): any {
    let errors = {
      fantasy_name: false,
      accountable: false,
      company_name: false,
      public_place: false,
      cnpj: false,
      city: false,
      uf: false
    };

    //validate fantasy_name
    let validator = new ValidatorUtils(this.fantasy_name);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.fantasy_name = true;

    //validate accountable
    validator = new ValidatorUtils(this.accountable);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.accountable = true;

    //validate company_name
    validator = new ValidatorUtils(this.company_name);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.company_name = true;

    //validate public_place
    validator = new ValidatorUtils(this.public_place);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.public_place = true;

    //validate cnpj
    validator = new ValidatorUtils(this.cnpj);
    validator.applyRule('is-valid-cnpj');
    if (!validator.validate()) errors.cnpj = true;

    //validate city
    validator = new ValidatorUtils(this.city);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.city = true;

    //validate UF
    validator = new ValidatorUtils(this.uf);
    validator.applyRule('is-valid-uf');
    if (!validator.validate()) errors.uf = true;

    return errors;
  }

}
