import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ConstantsService } from '../../../service/constants.service';

import { ValidatorUtils } from '../../../utils/validator.utils';

@Component({
  selector: 'modal-new-establishment',
  templateUrl: './modal-new-establishment.component.html',
  styleUrls: ['./modal-new-establishment.component.css']
})

export class ModalNewEstablishmentComponent {

  constructor(
    public modalRef: MDBModalRef,
    private constantsService: ConstantsService
  ) { }

  action: Subject<any> = new Subject();

  local_list: string[] = ['Rj', 'Sp'];
  select_local = this.local_list[0];

  active_list: string[] = ['Lorem Ipson Dolor', 'Teste 2'];
  select_active = this.active_list[0];

  modules = Modules;
  groups = [];

  user_name;
  user_email;
  user_cnpj;
  user_password;
  user_password2;

  loading: boolean = false;
  fantasy_name: string;
  accountable: string;
  company_name: string;
  public_place: string;
  cnpj: string;
  city: string;
  uf: string;
  segments = []
  selected_segment;

  cnpj_mask = '00.000.000/0000-00';
  error_message: string = "";

  statusScreen = false;

  ngOnInit() {
    this.constantsService.getConstants().subscribe(data => {
      this.segments = data.segments;
      this.groups = data.groups;
    })
  }

  nextScreen() {
    this.statusScreen = true;
  }

  backScreen() {
    this.statusScreen = false;
  }

  save() {
    this.error_message = "";

    let validator = new ValidatorUtils(this.fantasy_name);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira o nome fantasia"
      return
    }

    validator = new ValidatorUtils(this.accountable);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira o responsável"
      return
    }

    validator = new ValidatorUtils(this.company_name);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira a razão social"
      return
    }

    validator = new ValidatorUtils(this.public_place);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira o logradouro"
      return
    }

    validator = new ValidatorUtils(this.cnpj);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira o CNPJ"
      return
    } else if (this.cnpj.length < 14) {
      this.error_message = "Insira um CNPJ válido"
      return
    }

    validator = new ValidatorUtils(this.city);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Insira a cidade"
      return
    }

    validator = new ValidatorUtils(this.uf);
    validator.applyRule('is-valid-uf');
    if (!validator.validate()) {
      this.error_message = "Insira um UF válido"
      return
    }

    validator = new ValidatorUtils(this.selected_segment);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      this.error_message = "Selecione o segmento"
      return
    }

    this.action.next({
      'fantasy_name': this.fantasy_name,
      'company_name': this.company_name,
      'cnpj': this.cnpj,
      'public_place': this.public_place,
      'city': this.city,
      'uf': this.uf,
      'accountable': this.accountable,
      'segment': this.selected_segment,
      'invoice_entry': this.modules[0].value,
      'stock_discharge': this.modules[1].value,
      'cmv_management': this.modules[3].value,
      'shopping_chart': this.modules[2].value,
      'groups': this.groups,
    });
    this.modalRef.hide();

  }

}

var Modules: any[] = [
  { name: 'Entrada de NF', value: false },
  { name: 'Baixa de Estoque', value: false },
  { name: 'Gráfico de compras', value: false },
  { name: 'Gestão de CMV', value: false }
];
