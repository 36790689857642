import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../service/auth.service';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  email: string;
  password: string;
  keepLoggedIn: boolean = false;
  is_admin: boolean = false;

  establishments = [];
  selected_establishment;

  error_message: string = "";
  loading: boolean = false;
  page: number = 1;

  ngOnInit() {

  }

  login() {
    this.error_message = "";

    if (this.email == "" || this.email == null) {
      this.error_message = "Insira o email";
    }
    else if (this.password == "" || this.password == null) {
      this.error_message = "Insira a senha";
    }
    else {
      this.loading = true;

      this.authService.login(this.email, this.password, this.keepLoggedIn).subscribe(
        (data) => {
          if (data && data.results && data.results[0]) {
            this.is_admin = data.results[0].is_admin

            if (this.is_admin) {
              this.router.navigate(['/main/establishments/']);
            }
            else if (data.results[0].establishments.length > 1) {
              this.page = 2;
              this.establishments = data.results[0].establishments
              this.loading = false;
            }
            else if (data.results[0].establishments.length <= 0){
              this.error_message = 'Não foi possível fazer o login';
              this.loading = false;
              localStorage.clear()
            }
            else {
              this.redirect(data.results[0].establishments[0])
            }
          }
        },
        (err) => {
          this.router.navigateByUrl('/');
          this.error_message = 'Usuário ou senha inválidos';
          this.loading = false;
          console.error('login', err);
        }
      );
    }
  }

  selectEstablishment() {
    if (this.selected_establishment != null && this.selected_establishment != "") {
      this.loading = true;
      this.redirect(this.selected_establishment)
    }
    else {
      this.error_message = "Selecione um estabelecimento";
    }
  }

  redirect(establishment) {
    this.storageService.setCurrentEstablishment(establishment.id);
    localStorage.setItem('establishment_name', establishment.fantasy_name)
    this.storageService.setCurrentEstablishmentPermissions({
      est_invoice: establishment.invoice_entry,
      est_stock: establishment.stock_discharge,
      est_charts: establishment.shopping_chart,
      est_cmv: establishment.cmv_management,
      est_goal: establishment.use_goal
    });

    this.router.navigate(['/main/home']);
  }

}
