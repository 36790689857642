import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class CMVService {

  constructor(private http: HttpClient) { }

  getRevenues(establishment: number, date_from: string, date_to: string, cost_center): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from)
        .set('date_to', date_to)
        .set('page_size', "50")
    };

    if(cost_center != null && cost_center != ""){
      options.params = options.params.append('cost_center', cost_center)
    }

    return this.http.get<any>(environment.path + "core/revenue/", options);
  }

  postRevenues(revenues: any): Observable<any> {
    const body = new HttpParams()
      .set('revenues', JSON.stringify(revenues))

    return this.http.post<any>(environment.path + "core/revenue/", body.toString());
  }

  getRevenuesInfo(establishment: number, date_from: string, date_to: string, cost_center): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
    };

    if(cost_center != null && cost_center != ""){
      options.params = options.params.append('cost_center', cost_center)
    }

    return this.http.get<any>(environment.path + "core/revenue/revenue-info-new/", options);
  }

  getTotalKg(establishment: number, date_from: string, date_to: string, cost_center): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
    };

    if(cost_center != null && cost_center != ""){
      options.params = options.params.append('cost_center', cost_center)
    }

    return this.http.get<any>(environment.path + "core/total-kg-sold/", options);
  }

  postTotalKg(total_kg: any): Observable<any> {
    const body = new HttpParams()
      .set('total_kg_sold', JSON.stringify(total_kg))

    return this.http.post<any>(environment.path + "core/total-kg-sold/", body.toString());
  }

}
