import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { DatesService } from 'src/app/service/dates.service';
import { ProductService } from 'src/app/service/product.service';
import { ReceiptService } from 'src/app/service/receipt.service';

@Component({
  selector: 'app-modal-edit-invoice',
  templateUrl: './modal-edit-invoice.component.html',
  styleUrls: ['./modal-edit-invoice.component.css']
})

export class ModalEditInvoiceComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    public receiptService: ReceiptService,
    private datesService: DatesService,
    private productService: ProductService
  ) { }

  action: Subject<any> = new Subject();

  productObject;
  productArray;
  product = {} as ProductEntry;
  search_text;
  establishmentId;

  /* EDIT */
  supplier
  number;
  dayList;
  select_day;
  monthList = [];
  select_month;
  yearhList;
  select_year;
  yearList = [];

  entry_id;
  product_id;
  product_group: any;
  product_group_id;
  product_unity: any;
  product_unity_id;
  product_quantity;
  product_price;
  product_total;

  textButton: string = 'Próximo';
  statusScreen: number = 1;

  msg_erro = ""
  loading: boolean = false;

  ngOnInit() {
    this.supplier = this.productObject.supplier;
    this.number = this.productObject.number;

    var auxDate = this.productObject.date.split('-');

    this.select_day = auxDate[2]
    this.select_month = auxDate[1]
    this.select_year = auxDate[0]

    this.loadDate();

    this.entry_id = this.productObject.entryId;
    this.product_id = this.productObject.productId
    this.search_text = this.productObject.name;
    this.product_group = this.productObject.groupName;
    this.product_unity = this.productObject.measurementUnity.name;
    this.product_quantity = this.productObject.quantity;
    this.product_price = this.productObject.unitPrice;
    this.product_total = this.productObject.total;
    //this.searchProduct(this.productObject.name)
  }

  loadDate() {
    this.monthList = this.datesService.month_list;

    const result = this.monthList.find(arary => arary.number === this.select_month);
    this.select_month = result;

    this._loadYearList();
    this.reloadDatePicker();
  }

  searchProducts(text) {
    this.product_id = null
    this.productService.getProducts(1, this.establishmentId, text, 50).subscribe(
      (data) => {
        if (data && data.results) {
          this.productArray = data.results.filter(function (value, index, arr) {
            return value.status == true
          })
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  calculePriceTotal() {
    this.product_total = this.product_quantity * this.product_price;
  }

  setProductInfo(product) {
    this.product_id = product.id;
    this.product_group = product.group.name;
    this.product_group_id = product.group.id;
    this.product_unity = product.measurement_unit.name;
    this.product_unity_id = product.measurement_unit.id;
  }

  check(): boolean {
    this.msg_erro = ""
    if (this.product_id == "" || this.product_id == null) {
      this.msg_erro = "Selecione o produto."
      return false;
    }
    else if (this.product_quantity == "" || this.product_quantity == null) {
      this.msg_erro = "Digite a quantidade de itens."
      return false;
    }
    else if (this.product_price == "" || this.product_price == null) {
      this.msg_erro = "Digite o valor do item."
      return false;
    }
    else if (this.product_total == "0.00" || this.product_total == null) {
      this.msg_erro = "Digite o valor total dos itens."
      return false;
    }
    else return true;
  }

  nextPage() {
    this.msg_erro = "";
    if (this.statusScreen == 1) {

      if (this.supplier == null || this.supplier == "") {
        this.msg_erro = "Por favor, insira o fornecedor"
      }
      else if (this.number == null || this.number == "") {
        this.msg_erro = "Por favor, insira o número da NF"
      }
      else {
        this.statusScreen += 1;
        this.textButton = 'Salvar';
      }

    }
    else if (this.statusScreen == 2) {
      this.patchReceipts()
    }
  }

  backPage() {
    if (this.statusScreen == 1) {
      this.modalRef.hide();
    }
    else if (this.statusScreen == 2) {
      this.statusScreen -= 1;
      this.textButton = 'Próximo';
    }

  }

  patchReceipts() {
    var check = this.check()

    if (check && !this.loading) {
      this.loading = true;
      this.receiptService.patchInvoice(this._formatDate(), this.number, this.supplier,
        this.productObject.receiptId).subscribe(data => {

          this.receiptService.patchReceipts(this.entry_id, this.product_id, this.product_price,
            this.product_quantity).subscribe(data => {
              this.loading = false;
              this.action.next('yes');
              this.modalRef.hide();
            },
              (error) => {
                //console.error(error)
              })

        })
    }

  }

  reloadDatePicker() {
    this.dayList = [];
    const biggerMonths = ['Janeiro', 'Março', 'Maio', 'Julho', 'Agosto', 'Outubro', 'Dezembro'];

    if (biggerMonths.indexOf(this.select_month.name) >= 0) {
      for (let i = 1; i < 32; i++) {
        this.dayList.push(this._pad2Dig(i));
      }
    }
    else {
      if (this.select_month.name != 'Fevereiro') {
        for (let i = 1; i < 31; i++) {
          this.dayList.push(this._pad2Dig(i));
        }
      }
      else {
        if ((0 == parseInt(this.select_year) % 4) && (0 != parseInt(this.select_year) % 100) || (0 == parseInt(this.select_year) % 400)) {
          for (let i = 1; i < 30; i++) {
            this.dayList.push(this._pad2Dig(i));
          }
        }
        else {
          for (let i = 1; i < 29; i++) {
            this.dayList.push(this._pad2Dig(i));
          }
        }
      }
    }
  }

  private _loadYearList() {
    let d = new Date();
    for (let i = 2015; i < d.getFullYear() + 1; i++) {
      this.yearList.push(i.toString());
    }
  }

  private _pad2Dig(i) {
    return (i < 10) ? `0${i.toString()}` : i.toString();
  }

  private _formatDate(): string {
    //convert month to number;
    let monthNumber = this.monthList.indexOf(this.select_month) + 1;
    return `${this.select_year}-${this._pad2Dig(monthNumber)}-${this.select_day}`;
  }

}

export interface ProductEntry {
  id: number;
  name: string;
  group: any;
  groupId: number;
  measurementUnity: string;
  quantity: number;
  unitPrice: number;
  total: number;
}
