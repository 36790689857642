
//form field validations
export class ValidatorUtils {

  private _str: string;
  private _validator_state: boolean;

  constructor(str: any) {
    if (str)
      this._str = str.toString();
    else this._str = '';
    this._validator_state = true;
  }

  /* Apply rule */
  public applyRule(rule: string): ValidatorUtils {
    switch (rule) {
      case 'not-empty':
        if (this._validator_state)
          this._validator_state = this._notEmpty();
        break;
      case 'is-valid-uf':
        if (this._validator_state)
          this._validator_state = this._isValidUF();
        break;
      case 'is-valid-email':
        if (this._validator_state)
          this._validator_state = this._isValidEmail();
        break;
      case 'is-valid-cnpj':
        if (this._validator_state)
          this._validator_state = this._isValidCNPJ();
        break;
      case 'is-valid-float-number':
        if (this._validator_state)
          this._validator_state = this._isValidFloatNumber();
        break;
      case 'is-valid-int-number':
        if (this._validator_state)
          this._validator_state = this._isValidIntNumber();
        break;
    }

    return this;
  }


  /* return validator state */
  public validate(): boolean {
    return this._validator_state;
  }


  /* Run custom rule */
  public customRule(foo: Function): ValidatorUtils {
    if (this._validator_state)
      this._validator_state = foo();
    return this;
  }


  /* String should not be empty */
  private _notEmpty(): boolean {
    if ((this._str == null) || (this._str == '')) return false;
    return true;
  }


  /* Should be a valid UF */
  private _isValidUF(): boolean {
    const UF_str = 'AC,AL,AP,AM,BA,CE,DF,ES,GO,MA,MT,MS,MG,PA,PB,PR,PE,PI,RJ,RN,RS,RO,RR,SC,SP,SE,TO';
    let index = UF_str.split(',').findIndex((x) => x == this._str.toUpperCase());
    if (index < 0) return false;
    return true;
  }


  /* Should be a valid email */
  private _isValidEmail(): boolean {
    let re = /\S+@\S+\.\S+/;
    return re.test(this._str);
  }


  /* Should be a valid CNPJ */
  private _isValidCNPJ(): boolean {
    let cnpj = this._str;
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999") return false;

    let cnpj_size = cnpj.length - 2;
    let numbers = cnpj.substring(0, cnpj_size);
    let last_digit = cnpj.substring(cnpj_size);
    let sum = 0;
    let pos = cnpj_size - 7;
    for (let i = cnpj_size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(cnpj_size - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(last_digit.charAt(0)))
      return false;

    cnpj_size = cnpj_size + 1;
    numbers = cnpj.substring(0, cnpj_size);
    sum = 0;
    pos = cnpj_size - 7;
    for (let i = cnpj_size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(cnpj_size - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(last_digit.charAt(1)))
      return false;

    return true;
  }

  /* should be a valid  float number */
  private _isValidFloatNumber(): boolean {
    if (isNaN(+this._str)) return false;
    return true;
  }

  /* shoulg be a valid int number */
  private _isValidIntNumber(): boolean {
    if (parseInt(this._str) < 0) return false;
    if (!Number.isInteger(+this._str)) return false;
    return true;
  }
}
