import { MatTableDataSource } from '@angular/material/table';
import { ConstantsService } from './../../../service/constants.service';
import { ModalNewProductComponent } from './../../../components/modals/modal-new-product/modal-new-product.component';
import { ProductService } from './../../../service/product.service';
import { StorageService } from './../../../service/storage.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { DatesService } from 'src/app/service/dates.service';
import { StockService } from 'src/app/service/stock.service';

@Component({
    selector: 'app-stock-detail',
    templateUrl: './stock-detail.component.html',
    styleUrls: ['./stock-detail.component.css']
})

export class StockDetailComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private datesService: DatesService,
        private storageService: StorageService,
        private productService: ProductService,
        private modalService: MDBModalService,
        private constantsService: ConstantsService,
        private stockService: StockService
    ) { }

    action: Subject<any> = new Subject();
    selected_screen: number = 1;
    modalRef2: MDBModalRef;

    costCenters = [];
    groupList = [];
    unitList = [];
    selected_cost_center;
    establishmentId: number;
    discharges = [];
    old_values = {}

    days = [];
    selected_day;
    months = [];
    selected_month;
    years = [];
    selected_year;

    search_product: string = "";
    products = [];
    product = {} as ProductEntry;

    data_table = new MatTableDataSource<ProductEntry>();
    displayedColumns: string[] = ['name', 'unity', 'quantity', 'old'];

    position: number = 0;
    low_entries: ProductEntry[] = [];

    loading: boolean = false;
    status_edit: boolean = false;
    error_message: string = "";
    textButton1 = 'Cancelar';
    textButton2 = 'Próximo';
    textButton3 = 'Próximo';

    ngOnInit(): void {
        this.establishmentId = this.storageService.getCurrentEstablishment();

        var aux_day = new Date()
        this.selected_day = formatDate(aux_day, 'dd', 'en-US')

        this.months = this.datesService.month_list;
        this.selected_month = this.months[this.datesService.getActualMonth()]

        var aux_year = new Date()
        this.selected_year = formatDate(aux_year, 'yyyy', 'en-US')

        this._loadYearList()
        this._loadUnits()
        this.reloadDatePicker()
    }

    private _loadYearList() {
        let d = new Date();
        for (let i = 2020; i < d.getFullYear() + 1; i++) {
            this.years.push(i.toString());
        }
    }

    private _loadUnits() {
        this.constantsService.getConstants().subscribe(data => {
            this.unitList = data.measurement_units;
        })
    }

    reloadDatePicker() {
        this.days = [];
        const biggerMonths = ['Janeiro', 'Março', 'Maio', 'Julho', 'Agosto', 'Outubro', 'Dezembro'];

        if (biggerMonths.indexOf(this.selected_month.name) >= 0) {
            for (let i = 1; i < 32; i++) {
                this.days.push(this._pad2Dig(i));
            }
        }
        else {
            if (this.selected_month.name != 'Fevereiro') {
                for (let i = 1; i < 31; i++) {
                    this.days.push(this._pad2Dig(i));
                }
            }
            else {
                if (
                    (0 == parseInt(this.selected_year) % 4) &&
                    (0 != parseInt(this.selected_year) % 100) ||
                    (0 == parseInt(this.selected_year) % 400)
                ) {
                    for (let i = 1; i < 30; i++) {
                        this.days.push(this._pad2Dig(i));
                    }
                }
                else {
                    for (let i = 1; i < 29; i++) {
                        this.days.push(this._pad2Dig(i));
                    }
                }
            }
        }
    }

    private _pad2Dig(i) {
        return (i < 10) ? `0${i.toString()}` : i.toString();
    }

    formatDateToAPI(): string {
        //convert month to number;
        let monthNumber = this.months.indexOf(this.selected_month) + 1;
        return `${this.selected_year}-${this._pad2Dig(monthNumber)}-${this.selected_day}`;
    }

    formatDateToShow(): string {
        let monthNumber = this.months.indexOf(this.selected_month) + 1;
        return `${this.selected_day}-${this._pad2Dig(monthNumber)}-${this.selected_year}`;
    }

    back() {
        this.error_message = "";

        if (this.selected_screen == 1) {
            this.no()
        }
        else if (this.selected_screen == 2) {
            if (this.position == 0) {
                this.selected_screen = 1
                this.position = 0
            }
            else {
                this.position -= 1
                this.setProduct(this.low_entries[this.position])
            }
        }
        else if (this.selected_screen == 3) {
            this.position -= 1
            this.setProduct(this.low_entries[this.position])
            this.selected_screen = 2
            this.textButton3 = "Próximo"
        }
    }

    next() {
        if (this.selected_screen == 1 && this.checkPage1()) {
            this.selected_screen += 1
        }
        else if (this.selected_screen == 2 && this.checkPage2()) {
            this.saveEntryArray()
        }
        else if (this.selected_screen == 3 && this.low_entries.length > 0) {
            this.finish()
        }
    }

    getOldValues(product) {
        this.stockService.getProductDischarge(
            this.establishmentId,
            this.formatDateToAPI(),
            this.formatDateToAPI(),
            null,
            this.selected_cost_center.id,
            product
        ).subscribe(data => {
            if(data.length > 0 && data[0].discharges.length > 0){
                let discharges = data[0].discharges.filter(el => !el.is_adjustment)
                discharges.forEach(element => {
                    if (this.selected_cost_center.id == element.cost_center) {
                        this.old_values[product] = element
                    }
                });


            }
        })
    }

    editEntry(entry, index) {
        this.position = index
        this.setProduct(this.low_entries[this.position])
        this.selected_screen = 2
        this.status_edit = true
    }

    removeEntry() {
        this.low_entries.splice(this.position, 1)
        this.status_edit = false

        if (this.low_entries.length <= 0) {
            this.product = {} as ProductEntry
            this.search_product = ""
            this.textButton3 = "Próximo"
        }
        else {
            this.position -= 1
            this.selected_screen = 3
        }
    }

    saveEntryArray() {
        this.getOldValues(this.product.id)
        this.position += 1
        this.setProductArray(this.position - 1, this.product)

        if (this.low_entries[this.position] != null) {
            this.setProduct(this.low_entries[this.position])
        }
        else {
            this.product = {} as ProductEntry
            this.search_product = ""
        }
    }

    checkFinish() {
        if (this.checkPage2()) {
            this.saveEntryArray()
            this.selected_screen = 3
            this.data_table = new MatTableDataSource(this.low_entries)
            this.textButton3 = "Salvar"
            this.status_edit = false
        }
    }

    filterProducts(text, select: boolean = false) {
        this.productService.getProducts(1, this.establishmentId, text, 50).subscribe(
            (data) => {
                if (data && data.results) {
                    this.products = data.results.filter(el => el.status)
                    if (select) {
                        this.setProduct(this.products[0])
                    }
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    setProductArray(index, item) {
        this.low_entries[index] = {} as ProductEntry
        this.low_entries[index].id = item.id
        this.low_entries[index].name = item.name
        this.low_entries[index].group = item.group
        this.low_entries[index].groupId = item.group.id
        this.low_entries[index].measurement_unit = item.measurement_unit
        this.low_entries[index].quantity = item.quantity
        this.search_product = item.name
    }

    setProduct(item) {
        this.product.id = item.id
        this.product.name = item.name
        this.product.group = item.group
        this.product.groupId = item.group.id
        this.product.measurement_unit = item.measurement_unit
        this.product.quantity = item.quantity
        this.search_product = item.name
    }

    checkPage1(): boolean {
        this.error_message = '';

        if (this.selected_cost_center == null) {
            this.error_message = 'Selecione o Centro de Custo';
            return false
        }
        else if (this.selected_day == null) {
            this.error_message = 'Selecione o dia';
            return false
        }
        else if (this.selected_month == null) {
            this.error_message = 'Selecione o mês';
            return false
        }
        else if (this.selected_year == null) {
            this.error_message = 'Selecione o ano';
            return false
        }
        else {
            return true
        }
    }

    checkPage2(): boolean {
        this.error_message = '';

        if (this.product.group == null && this.product.quantity == null) {
            this.error_message = 'Retorne ou preencha todos os campos';
            return false
        }
        else if (this.product.group == null) {
            this.error_message = 'Selecione o produto';
            return false
        }
        else if (this.product.quantity == null) {
            this.error_message = 'Digite a quantidade';
            return false
        }
        else {
            return true
        }
    }

    addProduct() {
        this.modalRef2 = this.modalService.show(ModalNewProductComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                editting: false,
                groupList: this.groupList,
                unitList: this.unitList,
            }
        });

        let establishmentId = this.storageService.getCurrentEstablishment();
        this.modalRef2.content.action.subscribe(
            (res) => {
                if (res) {
                    res.list.forEach(element => {
                        this.productService.createProduct(element, establishmentId).subscribe(
                            (data) => {
                                //created
                            },
                            (err) => {
                                console.error(err);
                            }
                        );
                    });
                }
            }
        );
    }

    finish() {
        if (this.loading) return

        this.loading = true
        this.saveEntryRequest(0)
    }

    saveEntryRequest(entry_index) {
        if (entry_index + 1 > this.low_entries.length) {
            this.yes()
        }
        else {
            let item = this.low_entries[entry_index]
            this.stockService.addOrEditDischarge(
                this.establishmentId,
                item.id,
                this.formatDateToAPI(),
                item.quantity,
                this.selected_cost_center.id,
                false
            ).subscribe( data => {
                this.old_values[item.id] = data
                this.saveEntryRequest(entry_index + 1)
            }, error => {
                this.saveEntryRequest(entry_index + 1)
            })
        }
    }

    no() {
        this.action.next('no');
        this.modalRef.hide()
    }

    yes() {
        this.action.next({ entries: this.low_entries, date: this.formatDateToAPI() });
        this.modalRef.hide()
    }

}

export interface ProductEntry {
    id: number;
    name: string;
    group: any;
    groupId: number;
    measurement_unit: any;
    quantity: number;
}
