import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Constants } from '../model/constants';
import { Profile } from '../model/profile';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  constructor(private http: HttpClient) { }

  /**
   * Get app constansts
   * @return Constants
   */
  getConstants(): Observable<Constants> {
    return this.http.get<Constants>(environment.path + "core/constants/");
  }

  /**
   * Get list of profiles
   * @return Profiles
   */
  getProfiles(): Observable<Profile[]> {
    return this.http.get<Constants>(environment.path + "core/constants/")
      .pipe(map((data) => {
        return data.profiles;
      }));
  }

  contact(email, name, message): Observable<any> {
    const body = new HttpParams()
      .set('email', email)
      .set('name', name)
      .set('message', message)

    return this.http.post<any>(`${environment.path}core/contact/`, body.toString())
  }

  getClients(): Observable<any> {
    return this.http.get<any>(environment.path + "core/client-showcase/");
  }

}
