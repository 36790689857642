import { Component } from '@angular/core';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {

  constructor(private storageService: StorageService) { }

  permissions = { products: false, invoice: false, stock: false, charts: false, cmv: false, users: false }
  establishment_permissions = { est_invoice: false, est_stock: false, est_charts: false, est_cmv: false }

  ngOnInit() {
    this.permissions = this.storageService.getCurrentUserPermissions();
    this.establishment_permissions = this.storageService.getCurrentEstablishmentPermissions()
  }

}
