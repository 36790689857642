import { MatTableDataSource } from '@angular/material/table';
import { ConstantsService } from '../../../service/constants.service';
import { ModalNewProductComponent } from '../../../components/modals/modal-new-product/modal-new-product.component';
import { ProductService } from '../../../service/product.service';
import { StorageService } from '../../../service/storage.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { DatesService } from 'src/app/service/dates.service';
import { StockService } from 'src/app/service/stock.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { formattedError } from '@angular/compiler';

@Component({
    selector: 'app-stock-adjustment',
    templateUrl: './stock-adjustment.component.html',
    styleUrls: ['./stock-adjustment.component.css']
})

export class StockAdjustmentComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private datesService: DatesService,
        private storageService: StorageService,
        private productService: ProductService,
        private modalService: MDBModalService,
        private constantsService: ConstantsService,
        private stockService: StockService
    ) { }

    action: Subject<any> = new Subject();
    selected_screen: number = 1;
    modalRef2: MDBModalRef;

    costCenters = [];
    groupList = [];
    unitList = [];
    selected_cost_center;
    establishmentId: number;
    discharges = [];
    old_values = {}

    totalStock = 0

    selected_day;
    weeks = [];
    weeks_fixed = [];
    selected_week;
    months = [];
    selected_month;
    years = [];
    selected_year;

    search_product: string = "";
    products = [];
    product = {} as ProductEntry;

    data_table = new MatTableDataSource<ProductEntry>();
    displayedColumns: string[] = ['name', 'unity', 'quantity', 'old', 'final'];

    position: number = 0;
    low_entries: ProductEntry[] = [];

    loading: boolean = false;
    loading_old: boolean = false;
    status_edit: boolean = false;
    error_message: string = "";
    textButton1 = 'Cancelar';
    textButton2 = 'Próximo';
    textButton3 = 'Próximo';

    dateToSave = "";

    ngOnInit(): void {
        this.establishmentId = this.storageService.getCurrentEstablishment();

        this.months = this.datesService.month_list;
        this.selected_month = this.months[this.datesService.getActualMonth()]

        var aux_year = new Date()
        this.selected_year = formatDate(aux_year, 'yyyy', 'en-US')

        this.selected_week = this.weeks[0]

        this._loadYearList()
        this._loadUnits()
        this.setDateAdjustment()
    }

    private _loadYearList() {
        let d = new Date();
        for (let i = 2020; i < d.getFullYear() + 1; i++) {
            this.years.push(i.toString());
        }
    }

    private _loadUnits() {
        this.constantsService.getConstants().subscribe(data => {
            this.unitList = data.measurement_units;
        })
    }

    checkWeeksOptions(){
        this.weeks = []
        this.weeks_fixed.forEach(week => {
            // let days = this.datesService.changeDate(week, this.selected_month, this.selected_year)


            // if(days['day7'] != null && days['day7'].substring(5, 7) == this.selected_month.number){
                if(week != 'Todas as semanas'){
                    this.weeks.push(week)
                }
            // }
            // // else if(days['day6'] != null && days['day6'].substring(5, 7) == this.selected_month.number){
            // //     this.weeks.push(week)
            // // }
            // // else if(days['day5'] != null && days['day5'].substring(5, 7) == this.selected_month.number){
            // //     this.weeks.push(week)
            // // }
            // else
            if(week == this.selected_week){
                this.selected_week = null
                this.selected_screen = 1
            }
        })

        if(this.selected_week == null){
            this.selected_week = this.weeks[0]
        }
    }

    setDateAdjustment(){
        this.weeks_fixed = this.datesService.getWeeksOptions(this.selected_month, this.selected_year)
        this.weeks = this.datesService.getWeeksOptions(this.selected_month, this.selected_year)

        if(this.selected_week && this.selected_month && this.selected_year){
            let days = this.datesService.changeDate(this.selected_week, this.selected_month, this.selected_year)

            // if(days['day6'] != null){
                this.selected_day = days['day6']
            // }
            // else if(days['day7'] != null){
                // this.selected_day = days['day7']
            // }

            this.checkWeeksOptions()
        }
    }

    private _pad2Dig(i) {
        return (i < 10) ? `0${i.toString()}` : i.toString();
    }

    formatDateToAPI(): any {
        let days = this.datesService.changeDate(this.selected_week, this.selected_month, this.selected_year)
        let dateAjustment = 0;
        if(this.selected_week == 'Semana 1'){
            //Semana 1 pegar o dia 1º da Semana
            dateAjustment = this.DataAjusteWeek1(days);
        }else{
            // Demais semanas pegar o days.day1 = segunda feira
            dateAjustment = days.day1;
        }

        //return `${this.selected_year}-${this._pad2Dig(month)}-${day > 9 ? day : '0'+day}`;

        return dateAjustment;
    }

    DataAjusteWeek1(days):any {
        //criando lista com os dias e as datas
        let daysWeek = [];
        daysWeek[0] =  [new Date(days.day1).getUTCDate(),days.day1];
        daysWeek[1] =  [new Date(days.day2).getUTCDate(),days.day2];
        daysWeek[2] =  [new Date(days.day3).getUTCDate(),days.day3];
        daysWeek[3] =  [new Date(days.day4).getUTCDate(),days.day4];
        daysWeek[4] =  [new Date(days.day5).getUTCDate(),days.day5];
        daysWeek[5] =  [new Date(days.day6).getUTCDate(),days.day6];
        daysWeek[6] =  [new Date(days.day7).getUTCDate(),days.day7];

        let dayAjustment = 0;

        daysWeek.forEach((day,index) => {
            //Pagando o dia 1 da semana
            if(day[0]==1){
                dayAjustment = day[1];
            }
        });

        return dayAjustment;

    }

    formatDateToShow(): string {
        let monthNumber = this.months.indexOf(this.selected_month) + 1;
        return `${this.selected_day}-${this._pad2Dig(monthNumber)}-${this.selected_year}`;
    }

    back() {
        this.error_message = "";

        if (this.selected_screen == 1) {
            this.no()
        }
        else if (this.selected_screen == 2) {
            if (this.position == 0) {
                this.selected_screen = 1
                this.position = 0
            }
            else {
                this.position -= 1
                this.setProduct(this.low_entries[this.position])
            }
        }
        else if (this.selected_screen == 3) {
            this.position -= 1
            this.setProduct(this.low_entries[this.position])
            this.selected_screen = 2
            this.textButton3 = "Próximo"
        }
    }

    next() {
        if (this.selected_screen == 1 && this.checkPage1()) {
            this.selected_screen += 1
        }
        else if (this.selected_screen == 2 && this.checkPage2()) {
            this.saveEntryArray()
        }
        else if (this.selected_screen == 3 && this.low_entries.length > 0) {
            this.finish()
        }
    }

    getOldValues(count, product) {
        this.loading_old = true
        let days = this.datesService.changeDate(this.selected_week, this.selected_month, this.selected_year)

        this.totalStock = 0;

        this.stockService.getProductDischarge(
            this.establishmentId,
            days['day1'],
            days['day7'],
            null,
            this.selected_cost_center.id,
            product
        ).subscribe(data => {
            if(data.length > 0 && data[0].discharges.length > 0){
                let item = data[0]
                let discharges = data[0].discharges.filter(el => el.is_adjustment)
                let j = 0;
                let position = 0;

                discharges.forEach(element => {
                    const d = new Date(element.date);
                    let day = d.getDay();
                    if(day==5){
                        position =  j;
                    }
                    j++;
                });
                //Aqui só pode pegar sábado ou o primeiro dia da semana

                if (discharges.length > 0) {
                    this.dateToSave = discharges[position].date
                    discharges[position].before = item.before
                    discharges[position].entry = item.entry
                    discharges[position].all_discharges = item.all_discharges
                    discharges[position].date = item.date
                    this.old_values[product] = discharges[position]

                    this.totalStock += item.before
                    this.totalStock -= item.all_discharges
                    this.totalStock += item.entry

                    let entrou = 0;
                    discharges.forEach(element => {
                        if(element.cost_center == this.selected_cost_center.id){
                            this.old_values[product].quantity =  element.quantity
                            entrou = 1;
                        }
                    });

                    if(entrou==0){
                        this.old_values[product].quantity =  0
                    }




                }
                else {
                    item.quantity = 0
                    this.old_values[product] = item
                }





                /*
                this.old_values[product].quantity = 0


                */

            }else{
                this.totalStock += data[0].before
                this.totalStock -= data[0].all_discharges
                this.totalStock -= data[0].entry

                let item = data[0]
                this.old_values[product] = item
                this.old_values[product].before = item.before
                this.old_values[product].entry = item.entry
                this.old_values[product].all_discharges = item.all_discharges
            }

            this.loading_old = false
        }, error => {
            if(count < 5){
                this.getOldValues(count + 1, product)
            }
            // this.loading_old = false
        })


    }

    editEntry(entry, index) {
        this.position = index
        this.setProduct(this.low_entries[this.position])
        this.selected_screen = 2
        this.status_edit = true
    }

    removeEntry() {
        this.low_entries.splice(this.position, 1)
        this.status_edit = false

        if (this.low_entries.length <= 0) {
            this.product = {} as ProductEntry
            this.search_product = ""
            this.textButton3 = "Próximo"
        }
        else {
            this.position -= 1
            this.selected_screen = 3
        }
    }

    saveEntryArray() {
        this.getOldValues(0, this.product.id)
        this.position += 1
        this.setProductArray(this.position - 1, this.product)

        if (this.low_entries[this.position] != null) {
            this.setProduct(this.low_entries[this.position])
        }
        else {
            this.product = {} as ProductEntry
            this.search_product = ""
        }
    }

    checkFinish() {
        if (this.checkPage2()) {
            this.saveEntryArray()
            this.selected_screen = 3
            this.data_table = new MatTableDataSource(this.low_entries)

            this.textButton3 = "Salvar"
            this.status_edit = false
        }
    }

    filterProducts(text, select: boolean = false) {
        this.productService.getProducts(1, this.establishmentId, text, 50).subscribe(
            (data) => {
                if (data && data.results) {
                    this.products = data.results.filter(el => el.status)
                    if (select) {
                        this.setProduct(this.products[0])
                    }
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    setProductArray(index, item) {
        this.low_entries[index] = {} as ProductEntry
        this.low_entries[index].id = item.id
        this.low_entries[index].name = item.name
        this.low_entries[index].group = item.group
        this.low_entries[index].groupId = item.group.id
        this.low_entries[index].measurement_unit = item.measurement_unit
        this.low_entries[index].quantity = item.quantity
        this.search_product = item.name
    }

    setProduct(item) {
        this.product.id = item.id
        this.product.name = item.name
        this.product.group = item.group
        this.product.groupId = item.group.id
        this.product.measurement_unit = item.measurement_unit
        this.product.quantity = item.quantity
        this.search_product = item.name
    }

    checkPage1(): boolean {
        this.error_message = '';

        if (this.selected_cost_center == null) {
            this.error_message = 'Selecione o Centro de Custo';
            return false
        }
        else if (this.selected_week == null) {
            this.error_message = 'Selecione a semana';
            return false
        }
        else if (this.selected_month == null) {
            this.error_message = 'Selecione o mês';
            return false
        }
        else if (this.selected_year == null) {
            this.error_message = 'Selecione o ano';
            return false
        }
        else {
            return true
        }
    }

    checkPage2(): boolean {
        this.error_message = '';

        if (this.product.group == null && this.product.quantity == null) {
            this.error_message = 'Retorne ou preencha todos os campos';
            return false
        }
        else if (this.product.group == null) {
            this.error_message = 'Selecione o produto';
            return false
        }
        else if (this.product.quantity == null) {
            this.error_message = 'Digite a quantidade';
            return false
        }
        else {
            return true
        }
    }

    addProduct() {
        this.modalRef2 = this.modalService.show(ModalNewProductComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                editting: false,
                groupList: this.groupList,
                unitList: this.unitList,
            }
        });

        let establishmentId = this.storageService.getCurrentEstablishment();
        this.modalRef2.content.action.subscribe(
            (res) => {
                if (res) {
                    res.list.forEach(element => {
                        this.productService.createProduct(element, establishmentId).subscribe(
                            (data) => {
                                //created
                            },
                            (err) => {
                                console.error(err);
                            }
                        );
                    });
                }
            }
        );
    }

    finish() {
        if (this.loading) return

        this.loading = true
        this.saveEntryRequest(0)
    }

    saveEntryRequest(entry_index) {
        if (entry_index + 1 > this.low_entries.length) {
            this.yes()
        }
        else {

            let item = this.low_entries[entry_index]


            if(!this.dateToSave){
                this.dateToSave = this.formatDateToAPI()
            }

            this.stockService.addOrEditDischarge(
                this.establishmentId,
                item.id,
                this.dateToSave,
                item.quantity,
                this.selected_cost_center.id,
                true
            ).subscribe( data => {
                this.old_values[item.id] = data
                this.saveEntryRequest(entry_index + 1)
            }, error => {
                this.saveEntryRequest(entry_index + 1)
            })

        }
    }

    calcFinal(item){
        let old = this.old_values[item.id]
        let total = old.before - old.all_discharges + old.entry
        total = total + old.quantity - parseFloat(item.quantity)
        return total
    }

    no() {
        this.action.next('no');
        this.modalRef.hide()
    }

    yes() {
        this.action.next({ entries: this.low_entries, date: this.formatDateToAPI() });
        this.modalRef.hide()
    }

}

export interface ProductEntry {
    id: number;
    name: string;
    group: any;
    groupId: number;
    measurement_unit: any;
    quantity: number;
}
