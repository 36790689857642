import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'small-graphic',
  templateUrl: './small-graphic.component.html',
  styleUrls: ['./small-graphic.component.css']
})

export class SmallGraphicComponent {

  constructor() { }

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  @Input() barChartData: ChartDataSets[] = [
    { data: [68], label: 'Real' },
    { data: [35], label: 'Meta' }
  ];

  @Input() biggest = 1;

  @Input() graphicColor: Color[] = [{ backgroundColor: '#77B465' }, { backgroundColor: '#B1B1B1' }]

  @Input() height;

  barChartLabels: Label[] = [''];
  barChartType: ChartType = 'horizontalBar';
  barChartLegend = false;
  barChartPlugins: any = [pluginDataLabels, showZeroPlugin];
  barChartOptions: ChartOptions = {};

  ngOnInit() {
    if (this.biggest <= 0) this.biggest = 1
  }

  ngAfterViewInit() {
    this.charts.forEach((child, index) => {

      this.barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true, }, gridLines: { color: 'transparent' }, display: false
          }],
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: (this.biggest * 1.15)
            }
          }],
        },
        plugins: {
          datalabels: {
            anchor: 'end', align: 'end',
            font: { size: 10, family: 'Lato, sans serif' },
            formatter: function (value, context) { return `${value} %`; }
          }
        }
      }

      child.chart.options = this.barChartOptions;
      child.chart.update()
    });
  }

}

var showZeroPlugin = {
  beforeRender: function (chartInstance) {
    var datasets = chartInstance.config.data.datasets;

    for (var i = 0; i < datasets.length; i++) {
      var meta = datasets[i]._meta;
      // It counts up every time you change something on the chart so
      // this is a way to get the info on whichever index it's at
      var metaData = meta[Object.keys(meta)[0]];
      var bars = metaData.data;

      for (var j = 0; j < bars.length; j++) {
        var model = bars[j]._model;

        if (metaData.type === "horizontalBar" && model.base === model.x) {
          model.x = model.base + 2;
        } else if (model.base === model.y) {
          model.y = model.base - 2;
        }
      }
    }

  }
};
