import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNewInvoiceComponent } from '../../components/modals/modal-new-invoice/modal-new-invoice.component';
import { ReceiptService } from '../../service/receipt.service';
import { GroupsService } from '../../service/groups.service';
import { StorageService } from '../../service/storage.service';
import { GroupEstablishment } from '../../model/group';
import { ProductService } from '../../service/product.service';
import { Product } from '../../model/product';
import { ModalEditInvoiceComponent } from '../../components/modals/modal-edit-invoice/modal-edit-invoice.component';
import { DatesService } from '../../service/dates.service';
import { AlertService } from '../../service/alert.service';
import { PopupDownloadComponent } from '../../components/modals/popup-download/popup-download.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'invoice-entry',
  templateUrl: './invoice-entry.component.html',
  styleUrls: ['./invoice-entry.component.css']
})

export class InvoiceEntryComponent {

  constructor(
    private modalService: MDBModalService,
    private receiptService: ReceiptService,
    private datesService: DatesService,
    private groupService: GroupsService,
    private storageService: StorageService,
    private productService: ProductService,
    private alertService: AlertService
  ) { }

  displayedColumns: string[] = ['month', 'day', 'groupName', 'supplier', 'name', 'measurementUnity', 'quantity', 'unitPrice', 'total', 'number', 'option'];
  dataSource = new MatTableDataSource<any>();

  year_list = []
  filter_year;

  month_list = []
  filter_month;

  week_list = []
  filter_week;

  date_from;
  date_to;

  /* pagination related variables */
  current_page = 1;
  max_page_size = environment.page_size;
  total_entries: number;

  request;

  productList: Product[];

  groupList: GroupEstablishment[] = [];
  selectedGroup: any = "";

  search: string = "";
  is_searching: boolean = false;

  modalRef: MDBModalRef;

  loading: boolean = true;
  is_all: boolean = false;

  ngOnInit() {
    this.year_list = this.datesService.getYearsList()
    this.month_list = this.datesService.month_list
    // this.week_list = this.datesService.week_list

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_year = this.datesService.getActualYear()
    this.filter_week = '';

    this._loadGroupList();
    this._loadProductList(1);
  }

  changeDate() {
    this.week_list = this.datesService.getWeeksOptions(this.filter_month, this.filter_year)

    if(!this.week_list.includes(this.filter_week)) {
      this.filter_week = this.week_list[0]
      this.changeDate()
      return
    }

    let days = this.datesService.changeDate(this.filter_week, this.filter_month, this.filter_year)
    let first_last = this.datesService.lastDayOfMonth('01', this.filter_year, this.filter_month)

    if(this.filter_week == 'Todas as semanas') {
      this.date_from = first_last['first_day']
      this.date_to = first_last['last_day']
    }
    else if (this.filter_week == 'Semana 1') {
      this.date_from = first_last['first_day']
      this.date_to = days['day7']
    }
    else if (this.filter_week == 'Semana 6') {
      this.date_from = days['day1']
      this.date_to = first_last['last_day']
    }
    else if (this.filter_week == 'Semana 5') {
      if (days['day7'].substring(5, 7) != this.filter_month.number) {
        this.date_from = days['day1']
        this.date_to = first_last['last_day']
      }
      else {
        this.date_from = days['day1']
        this.date_to = days['day7']
      }
    }
    else {
      this.date_from = days['day1']
      this.date_to = days['day7']
    }

    this.loadInvoices(this.current_page, this.date_to, this.date_from)
  }

  loadInvoices(page, date_to, date_from) {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this.alertService.loaderSpinnerOpen()

    this.receiptService.getReceipts2(establishmentId, this.search, page, date_to, date_from,
      this.selectedGroup.id).subscribe(data => {
        this.alertService.loaderSpinnerClose()
        this.request = data;
        let flatArr = [];
        for (let d of data.results) {
          let flatEntry = {} as any;

          flatEntry.date = d.invoice.date;
          flatEntry.receiptId = d.invoice.id;
          flatEntry.number = d.invoice.number;
          flatEntry.supplier = d.invoice.supplier;

          flatEntry.entryId = d.id;
          flatEntry.measurementUnity = d.product.measurement_unit;
          flatEntry.name = d.product.name;
          flatEntry.quantity = d.quantity;
          flatEntry.unitPrice = d.unit_price;
          flatEntry.total = d.quantity * d.unit_price;
          flatEntry.productId = d.product.id

          flatEntry.groupName = d.product.group.name;

          flatArr.push(flatEntry);
        }
        this.total_entries = data.count;
        this.dataSource = new MatTableDataSource<any>(flatArr);
      })
  }

  openModalNewInvoice() {
    this.modalRef = this.modalService.show(ModalNewInvoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        productList: this.productList,
        productArray: this.productList,
        establishmentId: this.storageService.getCurrentEstablishment(),
        groupList: this.groupList
      }
    });

    this.modalRef.content.action.subscribe((res) => {
      if (res && res.data) this._saveEntries(res);
    });
  }

  openModalEditInvoice(dataNF) {
    this.modalRef = this.modalService.show(ModalEditInvoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        productObject: dataNF,
        productArray: this.productList,
        establishmentId: this.storageService.getCurrentEstablishment()
      }
    })
    this.modalRef.content.action.subscribe((res) => {
      if (res == 'yes') {
        this.changeDate()
        this._loadGroupList();
        this._loadProductList(1);
      }
    });
  }

  private _loadProductList(page) {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this.productService.getProducts(page, establishmentId, "").subscribe(
      (data) => {
        if (data && data.results) {
          this.productList = data.results.filter(function (value, index, arr) {
            return value.status == true
          })
        }
        this.loading = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  /* delete receipt */
  deleteEntry(data) {

    this.alertService.alertConfirmation('Deletando...', 'Deseja realmente deletar?').subscribe((res) => {
      if (res) {
        this.loading = true;
        this.receiptService.deleteEntry(data.entryId).subscribe(
          (data) => {
            this.changeDate()
            this._loadGroupList();
            this._loadProductList(1);
          },
          (err) => {
            console.error(err);
          }
        );
      }
    });

  }

  /* change to the nex page */
  nextPage() {
    if (this.request != null && this.request.next != null) {
      this.current_page += 1
      this.changeDate()
    }
  }

  /* change to previous page */
  prevPage() {
    if (this.request != null && this.request.previous != null) {
      this.current_page -= 1
      this.changeDate()
    }
  }

  searchInvoices() {
    if (this.search == "" || this.search == null) {
      this.is_searching = false;
      this.changeDate()
    }
    else {
      this.is_searching = true;
      this.changeDate()
    }
  }

  /**
   * Save receipt entries
   * @param entrieObj[]
   * @return void
   */
  private _saveEntries(res: any) {
    this.alertService.loaderSpinnerOpen();
    let establishmentId = this.storageService.getCurrentEstablishment();
    let entriesObjRequest = {} as any;

    entriesObjRequest.date = res.date;
    entriesObjRequest.number = res.number;
    entriesObjRequest.establishmentId = establishmentId;
    entriesObjRequest.supplier = res.supplier;
    entriesObjRequest.products = [];

    for (let e of res.data) {
      entriesObjRequest.products.push({
        'product': e.id,
        'unit_price': e.unitPrice,
        'quantity': e.quantity
      });
    }

    this.receiptService.createEntries(entriesObjRequest).subscribe(
      (data) => {
        this.alertService.loaderSpinnerClose();
        this.changeDate()
      },
      (err) => {
        this.alertService.loaderSpinnerClose();
        console.error(err);
      }
    );

  }

  /**
   * Load receipts to display on the screen
   * @return void
   */
  /*private _loadReceipts(search: string) {
    let establishmentId = this.storageService.getCurrentEstablishment();

    this.receiptService.getReceipts(establishmentId, search).subscribe(
      (data)=> {

        let flatArr = [];
        for(let d of data) {
          for(let e of d.entries) {
            let flatEntry = {} as any;

            flatEntry.date = d.date;
            flatEntry.receiptId = d.id;
            flatEntry.number = d.number;
            flatEntry.supplier = d.supplier;

            flatEntry.entryId = e.id;
            flatEntry.measurementUnity = e.measurementUnity;
            flatEntry.name = e.name;
            flatEntry.quantity = e.quantity;
            flatEntry.unitPrice = e.unitPrice;
            flatEntry.total = e.quantity * e.unitPrice;

            flatEntry.groupName = e.group.name;

            flatArr.push(flatEntry);
          }
        }
        this._updatePagination(flatArr.length, this.current_page);
        this.dataSource = new MatTableDataSource<any>(flatArr);
      },
      (err)=> {
        console.error(err);
      }
    );
  }*/

  /**
   * Load groups
   * @return void
   */
  private _loadGroupList() {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this.groupList = []

    this.groupService.getEstablishmentGroups(establishmentId).subscribe(
      (data) => {
        if (data && data.results) {
          for (let index = 0; index < data.results.length; index++) {
            if (data.results[index].status == true) {
              this.groupList.push(data.results[index])
              if (this.groupList.length == 1) {
                this.selectedGroup = "";
                this.changeDate()
              }
            }
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  openPopup(){
    this.modalRef = this.modalService.show(PopupDownloadComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        group_list: this.groupList,
        title: 'Download dos relatórios',
        screen: 'invoice-entry',
        establishment: this.storageService.getCurrentEstablishment(),
      }
    });
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

}

export interface ProductEntry {
  id: number;
  name: string;
  group: any;
  groupId: number;
  measurementUnity: string;
  quantity: number;
  unitPrice: number;
  total: number;
}
