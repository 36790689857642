import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { StorageService } from '../../service/storage.service';
import { AlertService } from '../../service/alert.service';
import { CostCenterService } from '../../service/cost-center.service';
import { ModalNewCostCenterComponent } from '../../components/modals/modal-new-cost-center/modal-new-cost-center.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.css']
})

export class CostCenterComponent {

  constructor(
    private modalService: MDBModalService,
    private costCenterService: CostCenterService,
    private storageService: StorageService,
    private alertService: AlertService
  ) { }

  /* ref to modal components */
  modalRef: MDBModalRef;

  /* Table related variables */
  displayedColumns: string[] = ['name', 'register', 'option'];
  data_source = new MatTableDataSource<any>();

  /* pagination related variables */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = environment.page_size;
  request_result;

  ngOnInit() {
    this.getCostCenters(this.page);
  }

  getCostCenters(page: number) {
    let establishmentId = this.storageService.getCurrentEstablishment();

    this.costCenterService.getCostCenters(page, establishmentId).subscribe(
      (data) => {
        this.data_source.data = data.results;
        this.request_result = data;
        this.page = page;

        // pagination calc
        this.count_total = data.count;
        this.page_count1 = 1 + ((page - 1) * this.page_size)
        if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
          this.page_count2 = this.count_total;
        }
        else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
      },
      (err) => {
        console.error(err);
      }
    )
  }

  detailCostCenter(obj){
		this.modalRef = this.modalService.show(ModalNewCostCenterComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: false,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				title: obj ? 'Editar' : 'Criar',
        name: obj ? obj.description : null,
        id: obj ? obj.id : null,
        establishment: this.storageService.getCurrentEstablishment()
			}
		})
		this.modalRef.content.action.subscribe( (result: any) => {
        if(result == "update"){
          this.getCostCenters(1)
			}
    });
	}

  deleteCostCenter(id: number) {
    this.alertService.alertConfirmation('Deletando...', 'Deseja realmente deletar?').subscribe((res) => {
      if (res) {
        this.costCenterService.deleteCostCenter(id).subscribe( data => {
          this.getCostCenters(this.page)
        })
      }
    });
  }

  next() {
		if (this.request_result.next != null) {
			this.data_source.data = []
			this.getCostCenters(this.page + 1)
		}
	}

	prev() {
		if (this.request_result.previous != null) {
			this.data_source.data = []
			this.getCostCenters(this.page - 1)
		}
	}

}
