import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { User, Users } from '../model/user';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class CostCenterService {

  constructor(private http: HttpClient) { }

  getCostCenters(page: number, establishment): Observable<any> {
    let page_size = environment.page_size;
    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('page_size', page_size.toString())
    };

    if (establishment != "" && establishment != null) {
      options.params = options.params.set('establishment', establishment)
    }

    return this.http.get<any>(`${environment.path}core/cost-center/`, options);
  }

  createCostCenter(description, establishment): Observable<any> {
    const body = new HttpParams()
      .set(`description`, description)
      .set('establishment', establishment);

    return this.http.post<any>(environment.path + "core/cost-center/", body.toString());
  }

  editCostCenter(id, description): Observable<void> {
    const body = new HttpParams()
      .set(`description`, description)

    return this.http.patch<void>(`${environment.path}core/cost-center/${id}/`, body.toString());
  }

  getCostCenter(id: number): Observable<any> {
    return this.http.get<any>(`${environment.path}core/cost-center/${id}/`);
  }

  deleteCostCenter(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.path}core/cost-center/${id}/`);
  }

}
