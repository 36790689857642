import { Component, OnInit } from '@angular/core';
import { ModalNewGroupComponent } from '../../components/modals/modal-new-group/modal-new-group.component';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { Establishment } from '../../model/establishment';
import { EstablishmentService } from '../../service/establishment.service';
import { EstablishmentGroups } from '../../model/group';
import { GroupsService } from '../../service/groups.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { AlertService } from '../../service/alert.service';
import { StorageService } from '../../service/storage.service';
import { ConstantsService } from '../../service/constants.service';

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.css']
})

export class EstablishmentComponent implements OnInit {

  constructor(
    private modalService: MDBModalService,
    private establishmentService: EstablishmentService,
    private groupsService: GroupsService,
    private authService: AuthService,
    private storageService: StorageService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private constantsService: ConstantsService
  ) { }

  /* reference to modal components */
  modalRef: MDBModalRef;

  /* current establishment */
  establishment = {} as Establishment;

  /* list of groups */
  groups: EstablishmentGroups;

  segments;
  selected_segment;

  ngOnInit() {
    this.establishment.fantasy_name = '';
    //load and set current establishment on local storage
    this._loadEstablishment(this.route.snapshot.params.id);

    this.constantsService.getConstants().subscribe(data => {
      this.segments = data.segments;
    })
  }

  /* */
  visit() {
    //establishment is already set on storage
    this.router.navigate(['/main/home']);
    localStorage.setItem('establishment_name', this.establishment.fantasy_name)
  }

  /* Handles modal to create groups */
  openModalNewGroup() {
    this.modalRef = this.modalService.show(ModalNewGroupComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
    });

    this.modalRef.content.action.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
        this._createGroup(res.name, res.status, res.CMV);
      }
    });
  }

  editEstablishment() {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this._editEstablishment(establishmentId);
  }

  /**
   * Edit establishment
   * @param {}
   * @return void
   */
  private _editEstablishment(id: number) {
    this.alertService.loaderSpinnerOpen();
    this.establishmentService.editEstablishment({
      'id': id,
      'fantasy_name': this.establishment.fantasy_name,
      'company_name': this.establishment.company_name,
      'cnpj': this.establishment.cnpj,
      'public_place': this.establishment.public_place,
      'city': this.establishment.city,
      'uf': this.establishment.uf,
      'accountable': this.establishment.accountable,
      'segment': this.selected_segment
    }).subscribe(
      (data) => {
        this.router.navigate(["/establishments"])
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Editado!', 'Estabelecimento editado com sucesso.');
      },
      (err) => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Erro!', 'Erro ao editar estabelecimento.', 2000);
        console.error(err);
        setTimeout(() => {
          this._loadEstablishment(this.storageService.getCurrentEstablishment());
        }, 2400);

      }
    );
  }

  /**
   * Load a establishment data based on id
   * @param id the id
   * @return void
   */
  private _loadEstablishment(id: number) {
    this.alertService.loaderSpinnerOpen();

    this.establishmentService.getEstablishmentById(id).subscribe(
      (data) => {
        this.storageService.setCurrentEstablishment(id);
        this.establishment = data;
        this.setModulesAtStorage(data)
        this.selected_segment = data.segment_obj.id
        this._loadGroups();
      },
      (err) => {
        this.alertService.loaderSpinnerClose();
        console.error(err);
      }
    );
  }


  /**
   * Load groups` list
   * @return void
   */
  private _loadGroups() {
    this.groupsService.getEstablishmentGroups(this.route.snapshot.params.id).subscribe(
      (data) => {
        data.results.sort(function compare(a, b) {
          if (a.group.name < b.group.name)
            return -1;
          if (a.group.name > b.group.name)
            return 1;
          return 0;
        });
        this.groups = data;
        this.alertService.loaderSpinnerClose();
      },
      (err) => {
        console.error(err);
      }
    );
  }


  setModulesAtStorage(establishment) {
    this.storageService.setCurrentEstablishmentPermissions({
      est_invoice: establishment.invoice_entry,
      est_stock: establishment.stock_discharge,
      est_charts: establishment.shopping_chart,
      est_cmv: establishment.cmv_management,
      est_goal: establishment.use_goal,
    });
  }


  /**
   * Create a new group
   * @param name group's name
   * @param status group's status
   * @param CMV groups's CMV
   * @return void
   */
  private _createGroup(name: string, status: boolean, CMV: boolean) {
    this.alertService.loaderSpinnerOpen();

    this.groupsService.createGroup(name).subscribe(
      (data) => {
        var id_group = data.id;

        this.groupsService.createGroupEstablishment(this.route.snapshot.params.id, id_group, status, CMV).subscribe(
          (data) => {
            this.modalRef.hide();
            this._loadGroups()
          },
          (err) => {
            console.error(err)
            this.modalRef.hide();
          }
        );

      },
      (err) => {
        console.error(err)
        this.modalRef.hide();
      }
    );
  }

  /**
   * Patch establishment's modules status
   * @param CMV groups's CMV
   * @return void
   */
  patchEstablishmentModules() {
    this.establishmentService.patchEstablishmentModules({
      'id': this.establishment.id,
      'invoice_entry': this.establishment.invoice_entry,
      'stock_discharge': this.establishment.stock_discharge,
      'cmv_management': this.establishment.cmv_management,
      'shopping_chart': this.establishment.shopping_chart,
      'use_goal': this.establishment.use_goal,
    }).subscribe(data => {
      this.setModulesAtStorage(data)
    },
      error => {
        //console.error(error)
      })
  }

  /**
   *  Patch group's status
   * @param id group's id
   * @param status group's status
   * @return void
   */
  slideStatus(id, status) {
    this.groupsService.patchGroupEstablishmentStatus(id, status).subscribe(data => {
    },
      error => {
        //console.error(error)
      })
  }

  /**
   * Patch group's cmv
   * @param id group's id
   * @param CMV groups's CMV
   * @return void
   */
  slideCmv(id, cmv) {
    this.groupsService.patchGroupEstablishmentCmv(id, cmv).subscribe(data => {
    },
      error => {
        //console.error(error)
      })
  }

}
