import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { Receipt } from '../model/receipt';
import { Entry } from '../model/receipt';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ReceiptService {

  constructor(private http: HttpClient) { }


  /**
   * Get all establishment's receipt
   * @param id establishment's id
   * return { Receipts[] }
   */
  getReceipts(id: number, search: string): Observable<Receipt[]> {
    let page_size = environment.page_size;

    return this.http.get<any>(`${environment.path}core/invoice/?establishment=${id}&search=${search}&page_size=${page_size}`).pipe(
      map((data) => {
        let receiptByEntry = [];
        if (data && data.results) {
          for (let r of data.results) {
            if (r.products.length == 0) continue;

            let receipt = {} as Receipt;
            receipt.entries = [];

            receipt.id = r.id;
            receipt.number = r.number;
            receipt.date = r.date;
            receipt.supplier = r.supplier;

            for (let p of r.products) {
              let entry = {} as Entry;
              entry.id = p.id;
              entry.name = p.product.name;
              entry.group = p.product.group;
              entry.measurementUnity = p.product.measurement_unit;
              entry.unitPrice = p.unit_price;
              entry.quantity = p.quantity;
              receipt.entries.push(entry);
            }

            receiptByEntry.push(receipt);
          }
        }
        return receiptByEntry;
      })
    );
  }

  /**
   * Get all establishment's receipt
   * @param id establishment's id
   * return { Receipts[] }
   */
  getReceipts2(id: number, search: string, page: number, date_to, date_from, group): Observable<any> {
    let page_size = environment.page_size;

    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('search', search.toString())
        .set('page_size', page_size.toString())
        .set('establishment', id.toString())
    };

    if (date_from != null && date_from != '') {
      options.params = options.params.set('date_from', date_from.toString())
    }

    if (date_to != null && date_to != '') {
      options.params = options.params.set('date_to', date_to.toString())
    }

    if (group != null && group != '') {
      options.params = options.params.set('group', group.toString())
    }

    return this.http.get<any>(`${environment.path}core/invoice-product/`, options)
  }

  /**
   * Delete receipt based on id
   * @param id entry id
   * @return void
   */
  deleteEntry(id: number) {
    return this.http.delete(`${environment.path}core/invoice-product/${id}/`);
  }

  /**
   * Create receipt
   * @param receiptObj
   * @return anyObservable
   */
  createEntries(entryObj): Observable<any> {
    const { date, number, establishmentId, products, supplier } = entryObj;


    const body = new HttpParams()
      .set(`date`, date)
      .set('number', number)
      .set('establishment', establishmentId)
      .set('supplier', supplier)
      .set('products', JSON.stringify(products));


    return this.http.post(`${environment.path}core/invoice/`, body.toString());
  }


  patchReceipts(id: number, produt: number, priceUni: number, quantity: number): Observable<any> {

    const body = new HttpParams()
      .set('product', produt.toString())
      .set('unit_price', priceUni.toString())
      .set('quantity', quantity.toString())

    return this.http.patch(`${environment.path}core/invoice-product/${id}/`, body.toString())
  }

  patchInvoice(date, number, supplier, id): Observable<any> {

    const body = new HttpParams()
      .set('date', date.toString())
      .set('number', number.toString())
      .set('supplier', supplier.toString())

    return this.http.patch(`${environment.path}core/invoice/${id}/`, body.toString())
  }

}
