import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GraphicsService } from 'src/app/service/graphics.service';
import { ChartOptions, ChartType, ChartDataSets, Chart } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DatesService } from 'src/app/service/dates.service';
import { LoginComponent } from '../login/login.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-historical-chart',
  templateUrl: './historical-chart.component.html',
  styleUrls: ['./historical-chart.component.scss']
})
export class HistoricalChartComponent implements OnInit {

  constructor(
    private graphicsService: GraphicsService,
    private datesService: DatesService
  ) { }

  year_list = []
  month_list = []

  list_month: any = []

  loading:boolean = true
  search_alert:boolean = false

  filter_month;
  filter_year;

  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: { xAxes: [{}], yAxes: [{
      display: true,
      ticks: {
          suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
          suggestedMax: (199334 + 1000),  // máximo sugerido para o eixo Y
      }
    }],
  },
  plugins: {
    minBarLength: 200,
    datalabels: {
      anchor: 'end',
      align: 'end',
      textAlign: "center",
      labels: {
        percentage: {
          font: {
            weight: 'bold',
            size: 12,
          },
          color: '#EF8A45',
          formatter: (value, context) => {
            // console.log("--->",context);
            if(context.dataIndex === 0){
              var formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });

              let price = formatter.format(value)
              return price.replace('R$', '').split(',')[0]
            }
            else if(context.dataIndex==1){
              let general_total_percent = Number(this.result[context.datasetIndex]['general_total_percent']).toFixed(1)
              return general_total_percent+"%"
            }
            else if(context.dataIndex == 2){
              let invoice_cmv_percent = Number(this.result[context.datasetIndex]['invoice_cmv_percent']).toFixed(1)
              return invoice_cmv_percent+"%"
            }
          }
        },
        value: {
          font: {
            size: 8
          },
          formatter: function (value, context) {
            return ""
          }
        }
      },
      font: { family: 'Lato, sans serif' }
    }
  },
  };
  barChartLabels: Label[] = ['Faturamento', 'Compras Totais (NF)', 'Compras CMV'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [];

  colors = ['#FD809C', '#5CB3EE', '#FDD677', '#12f6fc', '#AAC7D6', '#F68C90']

  result = []

  fields_option = [
    "all_revenue",
    "general_total",
    "invoice_cmv",
  ]

  Cols = []
  list_revenue = []

  range_dates = []

  displayedColumns: string[] = ['month', 'all_revenue', 'general_total', 'invoice_cmv'];
  data_source = [];


  ngOnInit(): void {
    this.month_list = this.datesService.month_list;
    this.year_list = this.datesService.getYearsList()
    this.filter_year = this.year_list[0]
    this.barChartData = [];
    this.getLast4Months()
  }

  createGraphic(){
    this.barChartData = [];

    this.Cols = []
    for (let i = 0; i < this.result.length; i++){
      let date = this.result[i]['dateFrom'].split("-")
      let month = parseInt(date[1])-1
      let label = this.month_list[month]['name']+"/"+ date[0]

      this.Cols.push(
        {
          "col":i,
          "label":label,
          "color":this.colors[i],
          "values":[]
        }
      )
    }

    let max = 0;

    for (let index = 0; index < 3; index++){
      let field = this.fields_option[index]

      for (let index2 = 0; index2 < this.result.length; index2++){
        let value = (this.result[index2][field] || 0).toFixed(2)
        this.Cols[index2]['values'].push(value)

        if(Number(value) > max){
          max = Number(value)
        }
      }
    }

    this.barChartOptions.scales.yAxes.forEach(element => {
      element.ticks.suggestedMax = (max + 5000)  // máximo sugerido para o eixo Y
    });

    for (let i = 0; i < this.Cols.length; i++){
      this.barChartData.push(
        {
          data: this.Cols[i]['values'],
          label: this.Cols[i]['label'],
          hoverBackgroundColor: this.Cols[i]['color'],
        }
      )
    }

    console.log(this.barChartData);

    this.data_source = []

    this.barChartData.forEach(bar => {
      this.data_source.push({
        month: bar.label,
        all_revenue: bar.data[0],
        general_total: bar.data[1],
        invoice_cmv: bar.data[2],
      });
   });

    console.log(this.data_source);

    this.barChartPlugins.push(
      {
        datalabels: {
          anchor: 'top',
          align: 'top',
          formatter: Math.round,
          font: {
              weight: 'bold',
          }
        }
      }
    )
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

  getGraphicsDashboardInvoicing2(){
    this.changeDate()
    this.filter_month = null

    this.loading = true
    this.graphicsService.getGraphicsDashboardInvoicing(this.range_dates).subscribe({
      next: data => {
        this.result = []
        this.result = data
        this.createGraphic()
        this.loading = false
        this.search_alert = false
      },
      error: error => {
        console.error(error);
      }
    });
  }

  graphicsDashboard(){
    this.getGraphicsDashboardInvoicing2()
  }

  remove(index: number){
    console.log(index);

    this.range_dates.splice(index, 1);

    this.search_alert = true
    this.range_dates = this.sortByDate(this.range_dates)

    this.getGraphicsDashboardInvoicing2()
  }

  changeDateYear() {
    let date = this.filter_year+"-"+this.filter_month['number']+"-01"
    let label = this.filter_month['name']+"/"+ this.filter_year

    let exist = false
    this.range_dates.forEach(item => {
      if(item.label==label){
        exist = true
      }
    })

    const ultimoDia: Date = new Date(this.filter_year, this.filter_month['num'], 0);

    if(!exist){
      this.search_alert = true
      this.range_dates.push({
        dateFrom: date,
        dateTo: this.formatDate(ultimoDia),
        label: label
      })
    }

    console.log(this.range_dates);

    this.range_dates = this.sortByDate(this.range_dates)
  }

  changeDate() {
    console.log(this.filter_month);

    if(this.filter_month){
      let date = this.filter_year+"-"+this.filter_month['number']+"-01"
      let label = this.filter_month['name']+"/"+ this.filter_year

      let exist = false
      this.range_dates.forEach(item => {
        if(item.label==label){
          exist = true
        }
      })

      const ultimoDia: Date = new Date(this.filter_year, this.filter_month['num'], 0);

      if(!exist){
        this.search_alert = true
        this.range_dates.push({
          dateFrom: date,
          dateTo: this.formatDate(ultimoDia),
          label: label
        })
      }

      console.log(this.range_dates);

      this.range_dates = this.sortByDate(this.range_dates)
    }

  }

  sortByDate(array: any[]): any[] {
    return array.sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime());
  }

  getLast4Months(){
    const dataAtual: Date = new Date();
    const ultimoMes: Date = new Date(dataAtual);

    ultimoMes.setMonth(dataAtual.getMonth());

    for (let i = 0; i < 4; i++) {
        const primeiroDia: Date = new Date(ultimoMes.getFullYear(), ultimoMes.getMonth() - 1, 1);
        const ultimoDia: Date = new Date(ultimoMes.getFullYear(), ultimoMes.getMonth(), 0);

        let label = this.month_list[ultimoDia.getMonth()]['name']+"/"+ ultimoDia.getFullYear()

        this.range_dates.push({
          dateFrom: this.formatDate(primeiroDia),
          dateTo: this.formatDate(ultimoDia),
          label: label
        })
        ultimoMes.setMonth(ultimoMes.getMonth() - 1);
    }
    this.range_dates = this.sortByDate(this.range_dates)

    this.getGraphicsDashboardInvoicing2()
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }
}
