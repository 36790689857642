import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})

export class AlertComponent {

  constructor(public modalRef: MDBModalRef) { }

  title: string;
  message: string;

  ngOnInit() {

  }

}
