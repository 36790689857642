import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNewUserComponent } from '../../components/modals/modal-new-user/modal-new-user.component';
import { UserService } from '../../service/user.service';
import { User, Users } from '../../model/user';
import { StorageService } from '../../service/storage.service';
import { AlertService } from '../../service/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent {

  constructor(
    private modalService: MDBModalService,
    private userService: UserService,
    private storageService: StorageService,
    private alertService: AlertService
  ) { }

  /* ref to modal components */
  modalRef: MDBModalRef;

  /* Table related variables */
  displayedColumns: string[] = ['name', 'email', 'profile', 'register', 'active', 'option'];
  data_source = new MatTableDataSource<User>();

  /* pagination related variables */
  current_page = 1;
  total_entries: number;
  users_get_reponse: Users;
  max_page_size = environment.page_size;

  search: string;
  is_searching: boolean = false;

  ngOnInit() {
    this.getUsers(this.current_page, "");
  }


  /* get the list of userrs */
  getUsers(page: number, search: string) {
    let establishmentId = this.storageService.getCurrentEstablishment();

    this.userService.getUsers(establishmentId, page, search).subscribe(
      (data) => {
        this.data_source.data = data.results;
        this.users_get_reponse = data;
        this._updatePagination(data.count);

      },
      (err) => {
        console.error(err);
      }
    );
  }


  /* Change to the next page */
  nextPage() {
    if (this.users_get_reponse.next != null) {
      if (this.current_page * environment.page_size < this.total_entries)
        this.current_page++;

      if (this.is_searching)
        this.getUsers(this.current_page, this.search);
      else
        this.getUsers(this.current_page, "")
    }
  }


  /* Change to the previous page */
  prevPage() {
    if (this.users_get_reponse.previous != null) {
      this.current_page--;
      if (this.current_page < 0) this.current_page = 0;

      if (this.is_searching)
        this.getUsers(this.current_page, this.search);
      else
        this.getUsers(this.current_page, "")
    }
  }

  changeStatus(status, user_id) {
    this.userService.editUserStatus(status, user_id).subscribe(data => {
    })
  }


  /* Handle modal to create/edit new user */
  openModalUser(id = null) {
    if (id) {
      this._editUser(id);
    }
    else {
      this._createNewUser();
    }
  }


  searchUsers() {
    if (this.search == "" || this.search == null) {
      this.is_searching = false;
      this.getUsers(1, "")
    }
    else {
      this.is_searching = true;
      this.getUsers(1, this.search)
    }
  }


  /* Delete user */
  _deleteUser(id: number) {
    this.userService.deleteUser(id).subscribe( data => {
      this._updateDataSource({ 'id': id });
      this._updatePagination(this.total_entries - 1);
    }, error => {
      console.error(error);
    });
  }

  /* Delete user */
  deleteUser(id: number) {
    this.alertService.alertConfirmation('Deletando...', 'Deseja realmente deletar?').subscribe((res) => {
      if (res) {
        this._deleteUser(id);
      }
    });
  }

  /**
   * Edit user
   * @return void
   */
  private _editUser(id: number) {
    let user = this.data_source.data.find(x => x.id == id);
    this.modalRef = this.modalService.show(ModalNewUserComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        'name': user.name,
        'email': user.email,
        'profile': user.profile_obj,
        'status': user.status
      }
    });

    this.modalRef.content.action.subscribe((res) => {
      this.modalRef.hide();
      if (res) {
        let status = (res.status.toUpperCase() == 'SIM') ? true : false;
        this.userService.editUser({
          'id': id,
          'name': res.name,
          'email': res.email,
          'profile': res.profile.id,
          'status': status
        }).subscribe(
          (data) => {

          },
          (err) => {
            console.error(err);

          },
          () => {
            this._updateDataSource({
              'id': id,
              'name': res.name,
              'email': res.email,
              'profile': res.profile,
              'status': status,
              'action': 'update'
            });
          }
        );
      }
    });
  }


  /**
   * Create new user
   * @return void
   */
  private _createNewUser() {
    this.modalRef = this.modalService.show(ModalNewUserComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
    });

    this.modalRef.content.action.subscribe((res) => {
      this.modalRef.hide()
      let establishmentId = this.storageService.getCurrentEstablishment();
      if (res) {
        this.alertService.loaderSpinnerOpen();
        this.userService.getUsers(null, 1, res.email).subscribe(data => {
          if (data.results.length > 0) {

            let user = data.results[0]
            let has_user = user.establishments.filter(el => el.id == establishmentId)
            if (has_user.length > 0) {
              this.alertService.loaderSpinnerClose();
              this.alertService.alert(
                'Erro ao criar usuário',
                "Já existe um usuário com o mesmo email neste estabelecimento",
                5000
              );
            }
            else {
              this.userService.addUserEstablishment(establishmentId, user.id).subscribe(data => {
                this.alertService.loaderSpinnerClose();
                this.alertService.alert(
                  'Criado!',
                  'Um usuário existente foi adicionado a este estabelecimento',
                  4000
                );
                setTimeout(() => {
                  this.getUsers(1, "");
                }, 2400);
              })
            }

          }
          else {
            this.createUser(res, establishmentId)
          }
        })
      }
    });
  }

  private createUser(res, establishmentId) {
    let status = (res.status.toUpperCase() == 'SIM') ? true : false;
    this.userService.createUser({
      'name': res.name,
      'email': res.email,
      'password': res.password,
      'profile': res.profile.id,
      'status': status,
    }).subscribe(data => {

      this.userService.addUserEstablishment(establishmentId, data.id).subscribe(data => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert('Criado!', 'Usuário criado com sucesso', 2000);
        setTimeout(() => {
          this.getUsers(1, "");
        }, 2400);
      })

    }, error => {
      this.alertService.loaderSpinnerClose();
      this.alertService.alert('Erro ao criar usuário', "Por favor, verifique as informções inseridas", 3000);
    });
  }


  /**
   * Update pagination control
   * @param total_entries the number of entries to be shown
   * @return void
   */
  private _updatePagination(total_entries: number) {
    this.total_entries = total_entries;
  }


  /**
   * Update material table data source
   * @param Object table columns
   * @return void
   */
  private _updateDataSource({
    id,
    name = '',
    email = '',
    profile = null,
    status = false,
    action = 'delete'
  }) {
    let el_index = this.data_source.data.findIndex((x) => (x.id == id));
    if (action == 'delete') {
      this.data_source.data.splice(el_index, 1);
      this.data_source = new MatTableDataSource<any>(this.data_source.data);
    }
    else {
      this.data_source.data[el_index].name = name;
      this.data_source.data[el_index].email = email;
      this.data_source.data[el_index].profile_obj.id = profile.id;
      this.data_source.data[el_index].profile_obj.name = profile.name;
      this.data_source.data[el_index].status = status;
    }
  }
}
