import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { map } from 'rxjs/operators';
import { LoaderSpinnerComponent } from '../pages/alerts/loader-spinner/loader-spinner.component';
import { AlertComponent } from '../pages/alerts/alert/alert.component';
import { AlertConfirmationComponent } from '../pages/alerts/alert-confirmation/alert-confirmation.component';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  constructor(private modalService: MDBModalService) { }

  private _subject = new Subject<any>();
  modalRef: MDBModalRef;

  /**
   * Open spinner loader
   * @return void
   */
  loaderSpinnerOpen(): void {
    if (this.modalRef) this.modalRef.hide();
    this.modalRef = this.modalService.show(LoaderSpinnerComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
    });
  }

  /**
   * Close spinner loader
   * @return void
   */
  loaderSpinnerClose(): void {
    this.modalRef.hide();
  }

  /**
   * Throws alert erro popup
   * @param title title
   * @param msg text displayed
   * @param timeout time to be closed
   * @return void
   */
  alert(title: string, msg: string = '', timeout: number = 3000): void {
    if (this.modalRef) this.modalRef.hide();
    this.modalRef = this.modalService.show(AlertComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        message: msg,
        title: title
      }
    });

    setTimeout(() => {
      this.modalRef.hide();
    }, timeout);
  }

  /**
   * Confirmation alert
   * @param title title
   * @param msg text displayed
   * @return response obserble to be subscribed
   */
  alertConfirmation(title: string, msg: string = ''): Observable<boolean> {
    if (this.modalRef) this.modalRef.hide();
    this.modalRef = this.modalService.show(AlertConfirmationComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        message: msg,
        title: title
      }
    });

    return this.modalRef.content.action.pipe(
      map(res => {
        this.modalRef.hide();
        if (res)
          return true;
        else
          return false;
      }));
  }
}
