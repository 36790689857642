import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { CostCenterService } from 'src/app/service/cost-center.service';

@Component({
  selector: 'modal-new-cost-center',
  templateUrl: './modal-new-cost-center.component.html',
  styleUrls: ['./modal-new-cost-center.component.css']
})

export class ModalNewCostCenterComponent {

  constructor(
    public modalRef: MDBModalRef,
    private costCenterService: CostCenterService
  ) { }

  /* used to generate response to the main component */
  action: Subject<any> = new Subject();

  establishment: number;
  id: number;
  title: string;
  error_message: string;
  name: string;
  error_name: boolean = false;
  loading: boolean = false;

  ngOnInit() {

  }

  save(){
    this.error_message = "";
    this.error_name = false;

    if(this.loading) return;

    if(this.name != null && this.name != '' && this.name.length > 3){
      this.loading = true;
      if(this.id != null){
        this.costCenterService.editCostCenter(this.id, this.name).subscribe( data => {
          this.finish()
        })
      }
      else{
        this.costCenterService.createCostCenter(this.name, this.establishment).subscribe( data => {
          this.finish()
        })
      }
    }
    else{
      this.error_name = true;
      this.error_message = "Por favor, insira um nome com mais que 3 caracteres"
    }
  }

  finish(){
    this.loading = false;
    this.action.next('update');
    this.modalRef.hide()
  }

}
