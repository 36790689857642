import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'alert-confirmation',
  templateUrl: './alert-confirmation.component.html',
  styleUrls: ['./alert-confirmation.component.css']
})

export class AlertConfirmationComponent {

  constructor(public modalRef: MDBModalRef) { }

  title: string;
  message: string;
  action: Subject<any> = new Subject();

  ngOnInit() {

  }

  /* Cancel */
  cancel() {
    this.action.next(false);
  }

  /* Proceed */
  confirm() {
    this.action.next(true);
  }

}
