import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { switchMap, map } from 'rxjs/operators';
import { Md5 } from "md5-typescript";
import { StorageService } from './storage.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  private _keepLogged = false;

  /**
   * Performs login and store user data
   * @param username
   * @param password
   * @param keepLogged
   * @return any
   */
  login(username: string, password: string, keepLogged: boolean): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', Md5.init(password).toUpperCase());

    return this.http.post<any>(`${environment.path}core/auth/`, body.toString())
      .pipe(switchMap((data) => {
        if (data && data.token) {
          this.storageService.storeToken(data.token);
        }
        return this.storageService.getUserData().pipe(map((userData) => {
          if (userData && userData.results && userData.results[0]) {

            this._setRole(userData.results[0].is_admin);
            this.storageService.setCurrentUserData({
              name: userData.results[0].name,
              profile: userData.results[0].profile_obj.name
            });
            this.storageService.setCurrentUserPermissions({
              products: userData.results[0].profile_obj.product_management,
              invoice: userData.results[0].profile_obj.invoice_entry,
              stock: userData.results[0].profile_obj.stock_discharge,
              charts: userData.results[0].profile_obj.shopping_chart,
              cmv: userData.results[0].profile_obj.cmv_management,
              users: userData.results[0].profile_obj.user_management,
            });
          }
          return userData;
        }));
      }));
  }

  /**
   * Perfoms logout and clear user data
   * @return void
   */
  logout(): void {
    this.storageService.clearUserData();
  }

  /**
   * Chech if is admin
   * @return boolean
   */
  isAdmin(): boolean {
    return this.storageService.isAdmin();
  }

  /**
   * Chech if is logged
   * @return boolean
   */
  isLogged(): boolean {
    if (this.storageService.getToken()) return true;
    return false;
  }

  private _setRole(isAdmin: boolean): void {
    this.storageService.setRole(isAdmin);
  }

}
