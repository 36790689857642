import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class GoalsService {

  constructor(private http: HttpClient) { }


  getProductDischarge(establishment: number, date_from: string, date_to: string, goal: boolean): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
        .set('goal', goal.toString())
    };

    return this.http.get<any>(environment.path + "core/investments/", options);
  }

  postGoals(goals: any): Observable<any> {
    const body = new HttpParams()
      .set('goals', JSON.stringify(goals))

    return this.http.post<any>(environment.path + "core/goal/", body.toString());
  }

  getGoalsRevenue(establishment: number, date_from: string, date_to: string, goal_type: string): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
        .set('goal_type', goal_type.toString())
    };

    return this.http.get<any>(environment.path + "core/goal/", options);
  }

  getMonthlyEarningProjection(establishment_id, month, year): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment_id)
        .set('month', month)
        .set('year', year)
    };

    return this.http.get<any>(environment.path + "core/monthly-earning-projection/", options)
  }

  postMonthlyEarningProjection(establishment_id, month, year, value, weeks): Observable<any> {
    const body = new HttpParams()
      .set('establishment', establishment_id)
      .set('month', month)
      .set('year', year)
      .set('value', value)
      .set('number_of_weeks', weeks)

    return this.http.post<any>(environment.path + "core/monthly-earning-projection/", body.toString())
  }

  patchMonthlyEarningProjection(projection_id, value, weeks): Observable<any> {
    const body = new HttpParams()
      .set('value', value)
      .set('number_of_weeks', weeks)

    return this.http.patch<any>(environment.path + `core/monthly-earning-projection/${projection_id}/`, body.toString())
  }

  postInvoiceGoal(goal_type, percent, monthly_earning_projection): Observable<any> {
    const body = new HttpParams()
      .set('goal_type', goal_type)
      .set('percent', percent)
      .set('monthly_earning_projection', monthly_earning_projection)

    return this.http.post<any>(environment.path + "core/invoice-goal/", body.toString())
  }

  patchInvoiceGoal(id, percent): Observable<any> {
    const body = new HttpParams()
      .set('percent', percent)

    return this.http.patch<any>(environment.path + `core/invoice-goal/${id}/`, body.toString())
  }

  postGroupGoal(group, percent, invoice_goal): Observable<any> {
    const body = new HttpParams()
      .set('group', group)
      .set('percent', percent)
      .set('invoice_goal', invoice_goal)

    return this.http.post<any>(environment.path + "core/group-goal/", body.toString())
  }

  patchGroupGoal(id, percent): Observable<any> {
    const body = new HttpParams()
      .set('percent', percent)

    return this.http.patch<any>(environment.path + `core/group-goal/${id}/`, body.toString())
  }

  getInvoiceEntry(date_from, date_to, establishment_id): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment_id)
        .set('date_to', date_to)
        .set('date_from', date_from)
    };

    return this.http.get<any>(environment.path + "core/invoice-product/invoice-group-value/", options)
  }

}
