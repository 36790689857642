import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { StorageService } from "./storage.service";
// import axios from "axios";

@Injectable({
  providedIn: 'root'
})

export class GraphicsService {

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getGraphicsTable(establishment: number, date_from: string, date_to: string, goal: boolean): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('establishment', establishment.toString())
        .set('date_from', date_from.toString())
        .set('date_to', date_to.toString())
        .set('goal', goal.toString())
    };
    return this.http.get<any>(environment.path + "core/investments/", options)
  }

  getGraphicsDashboardInvoicing(dates: any[]): Observable<any> {
    const body = new HttpParams()
      .set(`establishment`, this.storageService.getCurrentEstablishment().toString())
      .set('periods', JSON.stringify(dates));

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    return this.http.post<any>(environment.path + "core/dashboard-invoicing/", body, {headers});
  }

}
