import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/service/constants.service';
import { DatesService } from 'src/app/service/dates.service';
import { ProductService } from 'src/app/service/product.service';
import { StorageService } from 'src/app/service/storage.service';
import { ModalNewProductComponent } from '../modal-new-product/modal-new-product.component';

@Component({
  selector: 'modal-new-invoice',
  templateUrl: './modal-new-invoice.component.html',
  styleUrls: ['./modal-new-invoice.component.css']
})

export class ModalNewInvoiceComponent {

  constructor(
    public modalRef: MDBModalRef,
    private datesService: DatesService,
    private productService: ProductService,
    private modalService: MDBModalService,
    private constantsService: ConstantsService,
    private storageService: StorageService
  ) { }

  action: Subject<any> = new Subject();
  productList;
  groupList;
  statusScreen: number = 1;
  establishmentId;

  modalRef2: MDBModalRef;
  unitList = [];

  textButton1 = 'Cancelar';
  textButton2 = 'Próximo';
  textButton3 = 'Próximo';

  /* -- PAGE 1 -- */
  supplier: string;
  number: string;
  dayList;
  select_day;
  monthList = [];
  select_month;
  yearhList;
  select_year;
  yearList = [];

  /* -- PAGE 2 -- */
  statusEdit: boolean = false;
  num_produc;

  arrayNF = [];
  tableNF = [];
  position: number = 0;

  search_text;
  search_answer;
  product = {} as ProductEntry;
  quantity: number;
  unit_price: number;
  total_price: number;
  msg_error: string = '';
  valueTotal = 0;
  somaTotal: number = 0;

  /* -- PAGE 3 -- */
  data_product = new MatTableDataSource<ProductEntry>();
  displayedColumns: string[] = ['name', 'unity', 'quantity', 'unit_price', 'total_price'];


  ngOnInit() {
    this.valueTotal = 0;

    this.dayList = this.datesService.getActualDay();
    var aux_day = new Date()
    this.select_day = formatDate(aux_day, 'dd', 'en-US')

    this.monthList = this.datesService.month_list;
    this.select_month = this.monthList[this.datesService.getActualMonth()]

    this.yearhList = this.datesService.getActualYear();
    var aux_year = new Date()
    this.select_year = formatDate(aux_year, 'yyyy', 'en-US')

    this._loadYearList();
    this.reloadDatePicker();
    this._loadMeasurementUnits();
  }

  filterItems(text, select: boolean = false) {
    this.productService.getProducts(1, this.establishmentId, text, 50).subscribe(
      (data) => {
        if (data && data.results) {
          this.search_answer = data.results.filter(function (value, index, arr) {
            return value.status == true
          })
          if(select){
            this.getItem(this.search_answer[0])
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getItem(item) {
    this.product.id = item.id
    this.product.name = item.name
    this.product.group = item.group
    this.product.groupId = item.group.id
    this.product.measurement_unit = item.measurement_unit
  }

  updateButtons() {
    if (this.statusScreen == 1) {
      this.textButton1 = 'Cancelar';
      this.textButton2 = 'Próximo';
    }
    else if (this.statusScreen == 2) {
      this.textButton1 = 'Cancelar';
      this.textButton2 = 'Finalizar';
      this.textButton3 = 'Próximo';
    }
    else if (this.statusScreen == 3) {
      this.textButton1 = 'Cancelar';
      this.textButton2 = 'Salvar';
      this.textButton3 = "Finalizar";
    }
  }

  checkPage1() {
    this.msg_error = '';

    if (this.supplier == null) {
      this.msg_error = 'Digite o nome do fornecedor';
      return false
    }
    else if (this.number == null) {
      this.msg_error = 'Digite a NF';
      return false
    }
    else if (this.select_day == null) {
      this.msg_error = 'Selecione o dia';
      return false
    }
    else if (this.select_month == null) {
      this.msg_error = 'Selecione o mês';
      return false
    }
    else if (this.select_year == null) {
      this.msg_error = 'Selecione o ano';
      return false
    }
    else {
      return true
    }
  }

  checkPage2() {
    this.msg_error = '';

    if (this.product.group == null && this.product.quantity == null && this.product.unitPrice == null) {
      this.msg_error = 'Retorne ou preencha todos os campos';
      return false
    }
    else if (this.product.group == null) {
      this.msg_error = 'Selecione o produto';
      return false
    }
    else if (this.product.quantity == null) {
      this.msg_error = 'Digite a quantidade';
      return false
    }
    else if (this.product.unitPrice == null) {
      this.msg_error = 'Digite o valor unitário';
      return false
    }
    else {
      return true
    }
  }

  back() {
    this.msg_error = '';
    var tamArray = this.arrayNF.length;

    if (this.statusScreen == 2) {
      if (this.position != 0) {
        this.position -= 1;
        if (this.arrayNF[this.position] != null) {
          this.getViewArray(this.position)
        }
      }
      else {
        this.backPage()
      }
    }
    else {
      this.backPage()
    }

  }

  next() {
    this.msg_error = '';
    var tamArray = this.arrayNF.length;

    if (this.statusScreen == 1) {
      var check1 = this.checkPage1()
      if (check1) {
        this.nextPage()
      }
    }
    else if (this.statusScreen == 2) {
      var check2 = this.checkPage2()

      if (tamArray > this.position) {
        if (check2) {
          if (this.arrayNF[this.position] != null) {
            this.updateArray(this.position)
          }

          this.position += 1;
          this.product = {} as ProductEntry;
          this.search_text = null;
          if (this.arrayNF[this.position] != null) {
            this.getViewArray(this.position)
          }
        }
      }
      else if (tamArray == this.position) {
        if (check2) {
          this.addArray()
          this.position += 1;
        }
      }
    }
    else if (this.statusScreen == 3) {
      if (this.arrayNF != null && this.arrayNF.length != 0) {
        this.action.next({ data: this.arrayNF, supplier: this.supplier, date: this._formatDate(), number: this.number });
        this.modalRef.hide();
      }
      else {
        this.msg_error = "Nenhum produto adicionado a nota fiscal"
      }
    }

    this.totalSoma()
  }

  totalSoma() {
    this.somaTotal = 0;

    for (let index = 0; index < this.arrayNF.length; index++) {
      this.somaTotal += this.arrayNF[index].total
    }
  }

  backPage() {
    if (this.statusScreen == 1) {
      this.modalRef.hide()
    }
    else {
      this.statusScreen -= 1;
      if (this.arrayNF[this.position] != null) {
        this.getViewArray(this.position)
      }
      this.updateButtons()
    }
  }

  nextPage() {
    this.statusScreen += 1;
    if (this.arrayNF[this.position] != null) {
      this.getViewArray(this.position)
    }
    this.updateButtons()
  }

  addArray() {
    var check = true;

    if (check) {
      this.arrayNF.push(this.product)
      this.tableNF = this.arrayNF;
      this.data_product.data = this.tableNF
      this.product = {} as ProductEntry;
      this.search_text = null;
    }
  }

  checkFinish() {
    this.msg_error = '';

    var check2 = this.checkPage2()

    if (this.product.group == null && this.product.quantity == null && this.product.unitPrice == null) {
      this.msg_error = 'Retorne ou preencha todos os campos';
    }
    else if (this.arrayNF[this.arrayNF.length] == null && this.arrayNF[this.position] == null) {
      if (check2) {
        this.addArray()
        this.nextPage()
      }
    }
    else {
      this.updateArray(this.position)
      this.position = this.arrayNF.length - 1;
      this.nextPage()
    }
    this.totalSoma()
  }

  addProduct(){
    this.modalRef2 = this.modalService.show(ModalNewProductComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        editting: false,
        groupList: this.groupList,
        unitList: this.unitList
      }
    });

    let establishmentId = this.storageService.getCurrentEstablishment();
    this.modalRef2.content.action.subscribe(
      (res) => {
        if (res) {
          res.list.forEach(element => {
            this.productService.createProduct(element, establishmentId).subscribe(
              (data) => {
                //created
              },
              (err) => {
                console.error(err);
              }
            );
          });
        }
      }
    );
  }

  private _loadMeasurementUnits() {
    this.constantsService.getConstants().subscribe(data => {
      this.unitList = data.measurement_units;
    })
  }

  updateArray(posi) {
    this.search_text = this.arrayNF[posi].name;
    this.arrayNF[posi] = this.product;
    this.arrayNF[posi].name = this.search_text;
    this.tableNF = this.arrayNF;
    this.data_product.data = this.tableNF;
    // this.position = this.arrayNF.length-1;
  }

  getViewArray(position) {
    this.search_text = null;
    this.product = {} as ProductEntry;
    this.search_text = this.arrayNF[position].name;
    this.product.group = this.arrayNF[position].group;
    this.product.measurement_unit = this.arrayNF[position].measurement_unit;
    this.product.id = this.arrayNF[position].id;
    this.product.quantity = this.arrayNF[position].quantity;
    this.product.total = this.arrayNF[position].total;
    this.product.unitPrice = this.arrayNF[position].unitPrice;
  }

  reloadDatePicker() {
    this.dayList = [];
    const biggerMonths = ['Janeiro', 'Março', 'Maio', 'Julho', 'Agosto', 'Outubro', 'Dezembro'];

    if (biggerMonths.indexOf(this.select_month.name) >= 0) {
      for (let i = 1; i < 32; i++) {
        this.dayList.push(this._pad2Dig(i));
      }
    }
    else {
      if (this.select_month.name != 'Fevereiro') {
        for (let i = 1; i < 31; i++) {
          this.dayList.push(this._pad2Dig(i));
        }
      }
      else {
        if ((0 == parseInt(this.select_year) % 4) && (0 != parseInt(this.select_year) % 100) || (0 == parseInt(this.select_year) % 400)) {
          for (let i = 1; i < 30; i++) {
            this.dayList.push(this._pad2Dig(i));
          }
        }
        else {
          for (let i = 1; i < 29; i++) {
            this.dayList.push(this._pad2Dig(i));
          }
        }
      }
    }
  }

  private _loadYearList() {
    let d = new Date();
    for (let i = 2015; i < d.getFullYear() + 1; i++) {
      this.yearList.push(i.toString());
    }
  }

  private _pad2Dig(i) {
    return (i < 10) ? `0${i.toString()}` : i.toString();
  }

  private _formatDate(): string {
    //convert month to number;
    let monthNumber = this.monthList.indexOf(this.select_month) + 1;
    return `${this.select_year}-${this._pad2Dig(monthNumber)}-${this.select_day}`;
  }

  calcTotal() {
    this.product.total = this.product.quantity * this.product.unitPrice
  }

  editProduct(data, num) {
    this.num_produc = num
    this.product = {} as ProductEntry;

    this.product.id = data.id
    this.product.name = data.name;
    this.product.group = data.group;
    this.product.groupId = data.groupId;
    this.product.measurement_unit = data.measurement_unit;
    this.product.quantity = data.quantity;
    this.product.unitPrice = data.unitPrice;
    this.product.total = data.total;
    this.search_text = data.name

    this.statusEdit = true;
    this.statusScreen = 2;
  }

  removeProduct() {
    this.arrayNF.splice(this.num_produc, 1);
    this.product = {} as ProductEntry;
    this.search_text = null;
    this.statusEdit = false;
    this.position -= 1;
    this.statusScreen = 3;
  }

  saveEdit() {
    this.addArray()
    this.arrayNF.splice(this.num_produc, 1);
    this.statusEdit = false;
    this.statusScreen = 3;
    this.totalSoma()
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }
}

export interface ProductEntry {
  id: number;
  name: string;
  group: any;
  groupId: number;
  measurement_unit: any;
  quantity: number;
  unitPrice: number;
  total: number;
}
