import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { User } from '../../model/user';
import { AuthService } from '../../service/auth.service';
import { StorageService } from '../../service/storage.service';
import { UserService } from '../../service/user.service';
import { ConstantsService } from '../../service/constants.service';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})

export class SidenavComponent {

  constructor(
    public router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private userService: UserService,
    private constantsService: ConstantsService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.updatePermissions()

        if (this.router.url.substr(0, 5) == '/main') {
          this.styleItensByUrl(this.router.url)
        }

        if (this.router.url.substr(0, 19) == '/main/establishment') {
          this.establishment_name = null
        } else {
          this.establishment_name = localStorage.getItem('establishment_name')
        }
      }
    });
  }

  checkbox: boolean = false;
  user = {} as User;
  permissions = { products: false, invoice: false, stock: false, suggestion: true, charts: false, cmv: false, users: false }
  establishment_permissions = { est_invoice: false, est_stock: false, est_suggestion: true, est_charts: false, est_cmv: false, est_goal: false }
  establishment_name;
  profileName;

  config;
  has_goals: boolean = false;

  ngOnInit() {
    this._setUserData();
    this.getConstants()

    if (localStorage.getItem('ESTABLISHMENT_GOAL') == "true") this.has_goals = true;
  }

  getConstants() {
    this.constantsService.getConstants().subscribe(data => {
      this.config = data.config;
    })
  }

  /* performs logout and redirect to login page */
  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/')
  }

  /* style item based on the url */
  styleItensByUrl(url) {

    if (url == "/main/initial" || url == "/main/home" || url.substring(0, 19) == "/main/establishment") {
      document.getElementById('area-left-aberto').style.display = 'none';
      document.getElementsByClassName('logo-move')[0].setAttribute('style', 'display: none')
      document.getElementsByClassName('logo-stop')[0].setAttribute('style', 'display: block')
      document.getElementsByClassName('bt-menu')[0].setAttribute('style', 'display: none')
      this.checkbox = true;
    }
    else {
      document.getElementById('area-left-aberto').style.display = 'flex';
      document.getElementsByClassName('logo-move')[0].setAttribute('style', 'display: block')
      document.getElementsByClassName('logo-stop')[0].setAttribute('style', 'display: none')
      document.getElementsByClassName('bt-menu')[0].setAttribute('style', 'display: block')
      this.checkbox = false;
    }
  }

  /* update permissions */
  updatePermissions() {
    var establishment_id: string = localStorage.getItem('ESTABLISHMENT');
    var establishment_array;
    var select;

    if (this.storageService.isAdmin()) {
      var url = this.router.url
      if (url != "/main/initial" && url != "/main/home" && url.substring(0, 19) != "/main/establishment") {
        let aux = this.storageService.getCurrentEstablishmentPermissions();
        if (aux != "error") {
          this.establishment_permissions = aux
        }
        else {
          this.router.navigate(['/main/establishments/']);
        }

        this.permissions = { products: true, invoice: true, stock: true, suggestion: true, charts: true, cmv: true, users: true }
      }

    } else {

      this.userService.getUserMe().subscribe(data => {
        this.profileName = data.results[0].profile_obj;

        if(data.results[0].establishments.length <= 0 && !data.results[0].is_admin){
          localStorage.clear()
          this.router.navigate(['/'])
        }

        this.permissions = {
          products: data.results[0].profile_obj.product_management,
          invoice: data.results[0].profile_obj.invoice_entry,
          stock: data.results[0].profile_obj.stock_discharge,
          suggestion: true,
          charts: data.results[0].profile_obj.shopping_chart,
          cmv: data.results[0].profile_obj.cmv_management,
          users: data.results[0].profile_obj.user_management,
        }
        this.storageService.setCurrentUserPermissions(this.permissions);
        establishment_array = data.results[0].establishments;

        for (let index = 0; index < establishment_array.length; index++) {
          if (establishment_array[index].id == establishment_id || establishment_id == null) {
            select = establishment_array[index]
            let aux = {
              est_invoice: select.invoice_entry,
              est_stock: select.stock_discharge,
              est_suggestion: true,
              est_charts: select.shopping_chart,
              est_cmv: select.cmv_management,
              est_goal: select.use_goal
            }
            this.storageService.setCurrentEstablishmentPermissions(aux)
            this.establishment_permissions = aux;
            this.checkPermissionsUrl()
          }
        }
      })

    }
  }

  checkPermissionsUrl() {
    var url = this.router.url.split('/main/')

    if (url[1] == 'invoice-entry' && this.profileName.invoice_entry) {
      if (!this.establishment_permissions.est_invoice) {
        this.router.navigate(['/main/home']);
      }
    }
    else if (url[1] == 'low-stock' && this.profileName.stock_discharge) {
      if (!this.establishment_permissions.est_stock) {
        this.router.navigate(['/main/home']);
      }
    }
    else if (url[1] == 'graphics-shopping' && this.profileName.shopping_chart) {
      if (!this.establishment_permissions.est_charts) {
        this.router.navigate(['/main/home']);
      }
    }
    else if (url[1] == 'cmv-management' && this.profileName.cmv_management) {
      if (!this.establishment_permissions.est_cmv) {
        this.router.navigate(['/main/home']);
      }
    }
    else if (url[1] == 'goals' && this.profileName.shopping_chart) {
      if (!this.establishment_permissions.est_goal) {
        this.router.navigate(['/main/home']);
      }
    }
  }

  /**
   * Set user name
   * @return void
   */
  private _setUserData() {
    let { name, profile } = this.storageService.getCurrentUserData();
    this.user.name = name;
    this.user.profile = profile;
    this.user.is_admin = this.authService.isAdmin();
  }
}
