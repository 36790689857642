import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { EstablishmentGroups, Group, Groups } from '../model/group'
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class GroupsService {

  constructor(private http: HttpClient) { }




  /**
   * Get a list of groups
   * @return a list of groups
   */
  getGroups(): Observable<Groups> {
    return this.http.get<Groups>(`${environment.path}core/group/`);
  }


  /**
   * Get establishment list of groups
   * @param id establishment id
   * @return a list of groups
   */
  getEstablishmentGroups(id): Observable<EstablishmentGroups> {
    return this.http.get<EstablishmentGroups>(`${environment.path}core/establishment-group/?establishment=${id}`);
  }


  getShoppingSuggestion(establishment, safety_stock, group): Observable<EstablishmentGroups> {
    let options = {
      params: new HttpParams()
        .set(`establishment`, establishment)
        .set(`safety_stock`, safety_stock),

      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    if (group && group > 0) options.params = options.params.set(`group`, group);

    return this.http.get<EstablishmentGroups>(`${environment.path}core/shopping-suggestion/`, options)
  }


  /**
   * Create a new group
   * @param name group's name
   * @param status group's status
   * @param CMV group's CMV
   * @return just for error tracking
   */
  createGroup(name: string): Observable<any> {
    const body = new HttpParams()
      .set(`name`, name)

    return this.http.post<any>(environment.path + "core/group/", body.toString());
  }

  createGroupEstablishment(establishment: string, group: string, status: boolean, cmv: boolean): Observable<any> {

    const body = new HttpParams()
      .set(`establishment`, establishment)
      .set(`group`, group)
      .set(`status`, status.toString())
      .set(`cmv`, cmv.toString());

    return this.http.post<any>(environment.path + "core/establishment-group/", body.toString());
  }

  patchGroupEstablishmentStatus(id, status): Observable<any> {

    const body = new HttpParams()
      .set(`status`, status)

    return this.http.patch<any>(environment.path + `core/establishment-group/${id}/`, body.toString());
  }

  patchGroupEstablishmentCmv(id, cmv): Observable<any> {

    const body = new HttpParams()
      .set(`cmv`, cmv)

    return this.http.patch<any>(environment.path + `core/establishment-group/${id}/`, body.toString());
  }

}
