import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { Product } from '../model/product';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(private http: HttpClient) { }

  /**
   * Get a list of products
   * @param page current page to be displayed
   * @return any
   */
  getProducts(page: number, establishmentId: number, search: string, page_size: number = null): Observable<any> {
    page_size = page_size != null ? page_size : environment.page_size;
    if (page) {
      const options = {
        params: new HttpParams()
          .set('page', page.toString())
          .set('establishment', establishmentId.toString())
          .set('search', search.toString())
          .set('page_size', page_size.toString())
      };
      return this.http.get<any>(`${environment.path}core/product/`, options);
    }
    const options = {
      params: new HttpParams()
        .set('establishment', establishmentId.toString())
    };
    return this.http.get<any>(`${environment.path}core/product/`, options);
  }

  /**
   * Create a product
   * @param obj {name, groupId, measurementUnity}
   * @return any
   */
  createProduct(productObj: any, establishmentId: number): Observable<any> {
    let { name, groupId, measurementUnity, initial_price, initial_quantity } = productObj;

    let body = new HttpParams()
      .set('name', name)
      .set('group', groupId)
      .set('status', "true")
      .set('measurement_unit', measurementUnity.id)
      .set('establishment', establishmentId.toString());

    if((initial_quantity != null && initial_quantity != "" && initial_quantity > 0) ||
      (initial_price != null && initial_price != "" && initial_price > 0))
    {
      body = body.append('initial_quantity', initial_quantity)
      body = body.append('initial_price', initial_price)
    }

    return this.http.post<any>(`${environment.path}core/product/`, body.toString());
  }

  /**
   * Edit a product
   * @param obj {id, name, groupId, measurementUnity}
   * @return any
   */
  editProduct(productObj: any): Observable<any> {
    let { id, name, groupId, measurementUnity } = productObj;

    const body = new HttpParams()
      .set('name', name)
      .set('group', groupId)
      .set('measurement_unit', measurementUnity);

    return this.http.patch<any>(`${environment.path}core/product/${productObj.id}/`, body.toString());
  }

  /**
   * Delete product
   * @param id product's id
   * @return any
   */
  deleteProduct(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.path}core/product/${id}/`);
  }


  /**
   * Patch product status
   * @param status and product_id
   * @return user
   */
  editProductStatus(status: boolean, product_id: number): Observable<Product> {
    const body = new HttpParams()
      .set(`status`, status.toString())

    return this.http.patch<Product>(`${environment.path}core/product/${product_id}/`, body.toString());
  }

  uploadCSVFile(file, id): Observable<any> {
    let body = new FormData();
    body.append('csv', new Blob([file], { type: 'text/csv' }), file.name);
    body.append('establishment', id)

    return this.http.post<any>(`${environment.path}core/csv-save/`, body);
  }

  seeCSVFile(file, id): Observable<any> {
    let body = new FormData();
    body.append('csv', new Blob([file], { type: 'text/csv' }), file.name);
    body.append('establishment', id)

    return this.http.post<any>(`${environment.path}core/csv/`, body);
  }

}
