import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ValidatorUtils } from '../../../utils/validator.utils';
import { ConstantsService } from '../../../service/constants.service';
import { Profile } from 'src/app/model/constants';

@Component({
  selector: 'modal-new-user',
  templateUrl: './modal-new-user.component.html',
  styleUrls: ['./modal-new-user.component.css']
})

export class ModalNewUserComponent {

  constructor(
    public modalRef: MDBModalRef,
    private constantsService: ConstantsService
  ) { }

  /* used to generate response to the main component */
  action: Subject<any> = new Subject();

  /* utils */
  profile_list: Profile[] = [];
  status_list: string[] = ['Sim', 'Não'];
  errors: any;
  editing = false;

  /* user related variables */
  name: string;
  email: string;
  password: string;
  confirmation_password: string;
  selected_profile: Profile;
  selected_status: string;
  status: boolean;
  profile: Profile;
  msg_erro = "";

  ngOnInit() {
    //if receive a name switch to edit mode
    if (this.name) {
      this.editing = true;
      let status = (this.status == true ? 'Sim' : 'Não');
      this.selected_status = status;
      //this.selected_profile = this.profile_list[this.profile];
    }
    else {
      this.selected_profile = this.profile_list[0];
      this.selected_status = this.status_list[0];
    }

    this._loadProfileOptions();
  }


  /* create new user */
  createUser() {
    if (this.editing) {
      this.errors = this._validateEditForm();
      let any_error = Object.values(this.errors).find((x) => x == true);
      if (any_error) return;

      this.action.next({
        'name': this.name,
        'email': this.email,
        'profile': this.selected_profile,
        'status': this.selected_status
      });
    }
    else {
      this.errors = this._validateCreateForm();
      let any_error = Object.values(this.errors).find((x) => x == true);
      if (any_error) return;

      this.action.next({
        'name': this.name,
        'email': this.email,
        'password': this.password,
        'profile': this.selected_profile,
        'status': this.selected_status
      });
    }
  }


  /* cancel user creation */
  cancel() {
    this.action.next(null);
  }

  /**
   * Validate create form
   * @return an error object
   */
  private _validateCreateForm(): any {
    let errors = {
      name: false,
      email: false,
      password: false,
      profile: false
    };

    this.msg_erro = ""

    //validate profile
    let validator = new ValidatorUtils(this.selected_profile.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      errors.profile = true;
      this.msg_erro = "Senha incorreta."
    }

    //validate password
    validator = new ValidatorUtils(this.password);
    validator.applyRule('not-empty')
      .customRule(() => {
        if (this.password != this.confirmation_password)
          return false;
        return true;
      });
    if (!validator.validate()) {
      errors.password = true;
      this.msg_erro = "Digite uma senha valida."
    }

    //validate email
    validator = new ValidatorUtils(this.email);
    validator.applyRule('is-valid-email');
    if (!validator.validate()) {
      errors.email = true;
      this.msg_erro = "Digite um email."
    }


    //validate name
    validator = new ValidatorUtils(this.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) {
      errors.name = true;
      this.msg_erro = "Digite o nome."
    }

    return errors;
  }


  /**
   * Validate edit form
   * @return an error object
   */
  private _validateEditForm(): any {
    let errors = {
      name: false,
      email: false,
      password: false
    };

    //validate name
    let validator = new ValidatorUtils(this.name);
    validator.applyRule('not-empty');
    if (!validator.validate()) errors.name = true;

    //validate email
    validator = new ValidatorUtils(this.email);
    validator.applyRule('is-valid-email');
    if (!validator.validate()) errors.email = true;

    return errors;
  }

  /**
   * Load profile options
   */
  private _loadProfileOptions() {
    this.constantsService.getProfiles().subscribe((data) => {
      Array.prototype.push.apply(this.profile_list, data);
      //default profile
      if (this.profile == null)
        this.selected_profile = data[0];
      else {
        let x = data.find(x => x.id == this.profile.id);
        this.selected_profile = x;
      }

    });
  }
}
