import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../../service/storage.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { DatesService } from 'src/app/service/dates.service';
import { CMVService } from 'src/app/service/cmv.service';

@Component({
    selector: 'app-revenue-detail',
    templateUrl: './revenue-detail.component.html',
    styleUrls: ['./revenue-detail.component.css']
})

export class RevenueDetailComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private datesService: DatesService,
        private storageService: StorageService,
        private cmvService: CMVService
    ) { }

    action: Subject<any> = new Subject();
    selected_screen: number = 1;
    modalRef2: MDBModalRef;

    costCenters = [];
    selected_cost_center;
    establishmentId: number;
    discharges = [];
    old_values = {}

    days = [];
    selected_day;
    months = [];
    selected_month;
    years = [];
    selected_year;

    data_table = new MatTableDataSource<RevenueData>();
    displayedColumns: string[] = ['name', 'value', 'old'];

    position: number = 0;
    revenue_entries: RevenueData[] = [];
    selected_revenue: RevenueData = {} as RevenueData;

    loading: boolean = false;
    status_edit: boolean = false;
    error_message: string = "";
    textButton1 = 'Cancelar';
    textButton2 = 'Próximo';
    textButton3 = 'Próximo';

    ngOnInit(): void {
        this.establishmentId = this.storageService.getCurrentEstablishment();

        var aux_day = new Date()
        this.selected_day = formatDate(aux_day, 'dd', 'en-US')

        this.months = this.datesService.month_list;
        this.selected_month = this.months[this.datesService.getActualMonth()]

        var aux_year = new Date()
        this.selected_year = formatDate(aux_year, 'yyyy', 'en-US')

        this._loadYearList()
        this.reloadDatePicker()
    }

    private _loadYearList() {
        let d = new Date();
        for (let i = 2020; i < d.getFullYear() + 1; i++) {
            this.years.push(i.toString());
        }
    }

    reloadDatePicker() {
        this.days = [];
        const biggerMonths = ['Janeiro', 'Março', 'Maio', 'Julho', 'Agosto', 'Outubro', 'Dezembro'];

        if (biggerMonths.indexOf(this.selected_month.name) >= 0) {
            for (let i = 1; i < 32; i++) {
                this.days.push(this._pad2Dig(i));
            }
        }
        else {
            if (this.selected_month.name != 'Fevereiro') {
                for (let i = 1; i < 31; i++) {
                    this.days.push(this._pad2Dig(i));
                }
            }
            else {
                if (
                    (0 == parseInt(this.selected_year) % 4) &&
                    (0 != parseInt(this.selected_year) % 100) ||
                    (0 == parseInt(this.selected_year) % 400)
                ) {
                    for (let i = 1; i < 30; i++) {
                        this.days.push(this._pad2Dig(i));
                    }
                }
                else {
                    for (let i = 1; i < 29; i++) {
                        this.days.push(this._pad2Dig(i));
                    }
                }
            }
        }
    }

    private _pad2Dig(i) {
        return (i < 10) ? `0${i.toString()}` : i.toString();
    }

    formatDateToAPI(): string {
        //convert month to number;
        let monthNumber = this.months.indexOf(this.selected_month) + 1;
        return `${this.selected_year}-${this._pad2Dig(monthNumber)}-${this.selected_day}`;
    }

    formatDateToShow(): string {
        let monthNumber = this.months.indexOf(this.selected_month) + 1;
        return `${this._pad2Dig(monthNumber)}/${this.selected_year}`;
    }

    back() {
        this.error_message = "";

        if (this.selected_screen == 1) {
            this.no()
        }
        else if (this.selected_screen == 2) {
            if (this.position == 0) {
                this.selected_screen = 1
                this.position = 0
            }
            else {
                this.position -= 1
                this.setRevenue(this.revenue_entries[this.position])
            }
        }
        else if (this.selected_screen == 3) {
            this.position -= 1
            this.setRevenue(this.revenue_entries[this.position])
            this.selected_screen = 2
            this.textButton3 = "Próximo"
        }
    }

    next() {
        if (this.selected_screen == 1 && this.checkPage1()) {
            this.selected_screen += 1
            this.getOldValues()
        }
        else if (this.selected_screen == 2 && this.checkPage2()) {
            this.saveEntryArray()
        }
        else if (this.selected_screen == 3 && this.revenue_entries.length > 0) {
            this.finish()
        }
    }

    getOldValues() {
        let first_last = this.datesService.lastDayOfMonth('01', this.selected_year, this.selected_month)

        this.cmvService.getRevenues(
            this.establishmentId,
            first_last['first_day'],
            first_last['last_day'],
            this.selected_cost_center.id
        ).subscribe(data => {
            this.old_values = data.results.reduce(function (acc, item) {
                acc[item.date.slice(-2)] = item
                return acc
            }.bind(this), {})
        })
    }

    editEntry(entry, index) {
        this.position = index
        this.setRevenue(this.revenue_entries[this.position])
        this.selected_screen = 2
        this.status_edit = true
    }

    removeEntry() {
        this.revenue_entries.splice(this.position, 1)
        this.status_edit = false

        if (this.revenue_entries.length <= 0) {
            this.selected_revenue = {} as RevenueData
            this.textButton3 = "Próximo"
        }
        else {
            this.position -= 1
            this.selected_screen = 3
        }
    }

    saveEntryArray() {
        this.position += 1
        this.setRevenueArray(this.position - 1, this.selected_revenue)

        if (this.revenue_entries[this.position] != null) {
            this.setRevenue(this.revenue_entries[this.position])
        }
        else {
            this.selected_revenue = {} as RevenueData
        }
    }

    checkFinish() {
        if (this.checkPage2()) {
            this.saveEntryArray()
            this.selected_screen = 3
            this.data_table = new MatTableDataSource(this.revenue_entries)
            this.textButton3 = "Salvar"
            this.status_edit = false
        }
    }

    setRevenueArray(index, item) {
        this.revenue_entries[index] = {} as RevenueData
        this.revenue_entries[index].id = item.id
        this.revenue_entries[index].value = item.value
        this.revenue_entries[index].day = item.day
    }

    setRevenue(item) {
        this.selected_revenue.id = item.id
        this.selected_revenue.value = item.value
        this.selected_revenue.day = item.day
    }

    checkPage1(): boolean {
        this.error_message = '';

        if (this.selected_cost_center == null) {
            this.error_message = 'Selecione o Centro de Custo';
            return false
        }
        else if (this.selected_month == null) {
            this.error_message = 'Selecione o mês';
            return false
        }
        else if (this.selected_year == null) {
            this.error_message = 'Selecione o ano';
            return false
        }
        else {
            return true
        }
    }

    checkPage2(): boolean {
        this.error_message = '';

        if (this.selected_revenue.day == null) {
            this.error_message = 'Selecione o dia';
            return false
        }
        else if (this.selected_revenue.value == null) {
            this.error_message = 'Digite o valor';
            return false
        }
        else {
            return true
        }
    }

    finish() {
        if (this.loading) return

        this.loading = true
        this.saveEntryRequest(0)
    }

    saveEntryRequest(entry_index) {
        if (entry_index + 1 > this.revenue_entries.length) {
            this.yes()
        }
        else {
            let item = this.revenue_entries[entry_index]
            let old = this.old_values[item.id]
            let monthNumber = this.months.indexOf(this.selected_month) + 1;

            let aux = [{
                "establishment": this.establishmentId,
                "date": `${this.selected_year}-${this._pad2Dig(monthNumber)}-${item.day}`,
                "value": item.value,
                "cost_center": this.selected_cost_center.id
            }]

            this.cmvService.postRevenues(aux).subscribe(data => {
                this.saveEntryRequest(entry_index + 1)
            }, error => {
                this.saveEntryRequest(entry_index + 1)
            })

        }
    }

    convertMoney(money) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(money);
    }

    no() {
        this.action.next('no');
        this.modalRef.hide()
    }

    yes() {
        this.action.next({ entries: this.revenue_entries, date: this.formatDateToAPI() });
        this.modalRef.hide()
    }

}

export interface RevenueData {
    id: number;
    value: number;
    day: number;
}
