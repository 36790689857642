import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor(private http: HttpClient) { }

  private readonly NAME = 'NAME';
  private readonly PROFILE = 'PROFILE';
  private readonly ESTABLISHMENT = 'ESTABLISHMENT';
  private readonly TOKEN = 'TOKEN';
  private readonly IS_ADMIN = 'IS_ADMIN';
  private readonly PRODUCTS = 'PRODUCTS';
  private readonly INVOICE = 'INVOICE';
  private readonly STOCK = 'STOCK';
  private readonly CHARTS = 'CHARTS';
  private readonly CMV = 'CMV';
  private readonly USERS = 'USERS';
  private readonly ESTABLISHMENT_INVOICE = 'ESTABLISHMENT_INVOICE';
  private readonly ESTABLISHMENT_STOCK = 'ESTABLISHMENT_STOCK';
  private readonly ESTABLISHMENT_CHARTS = 'ESTABLISHMENT_CHARTS';
  private readonly ESTABLISHMENT_CMV = 'ESTABLISHMENT_CMV';
  private readonly ESTABLISHMENT_GOAL = 'ESTABLISHMENT_GOAL';

  /**
   * Get current user data from API
   * @retun Observable<any{userData}}
   */
  getUserData(): Observable<any> {
    return this.http.get<any>(environment.path + "core/user/?user=me");
  }

  /**
   * Set current establishment
   * @param establishmentId establisht's id
   * @return void
   */
  setCurrentEstablishment(establishmentId: number): void {
    localStorage.setItem(this.ESTABLISHMENT, establishmentId.toString());
  }

  /**
   * Get current establishment
   * @return establisht's id
   */
  getCurrentEstablishment(): number {
    return parseInt(localStorage.getItem(this.ESTABLISHMENT));
  }

  /**
   * Set current user permissions
   * @param establishmentObj object cointaining user permissions
   * @return void
   */
  setCurrentEstablishmentPermissions(establishmentObj: any): void {
    let { est_invoice, est_stock, est_charts, est_cmv, est_goal } = establishmentObj;
    localStorage.setItem(this.ESTABLISHMENT_INVOICE, est_invoice);
    localStorage.setItem(this.ESTABLISHMENT_STOCK, est_stock);
    localStorage.setItem(this.ESTABLISHMENT_CHARTS, est_charts);
    localStorage.setItem(this.ESTABLISHMENT_CMV, est_cmv);
    localStorage.setItem(this.ESTABLISHMENT_GOAL, est_goal);
  }

  getCurrentEstablishmentPermissions(): any {
    try {
      return {
        est_invoice: (localStorage.getItem(this.ESTABLISHMENT_INVOICE).toUpperCase() == 'TRUE') ? true : false,
        est_stock: (localStorage.getItem(this.ESTABLISHMENT_STOCK).toUpperCase() == 'TRUE') ? true : false,
        est_suggestion: true,
        est_charts: (localStorage.getItem(this.ESTABLISHMENT_CHARTS).toUpperCase() == 'TRUE') ? true : false,
        est_cmv: (localStorage.getItem(this.ESTABLISHMENT_CMV).toUpperCase() == 'TRUE') ? true : false,
        est_goal: (localStorage.getItem(this.ESTABLISHMENT_GOAL).toUpperCase() == 'TRUE') ? true : false,
      };
    } catch {
      return "error"
    }
  }

  /**
   * Set current user data
   * @param userObj object cointaining user data
   * @return void
   */
  setCurrentUserData(userObj: any): void {
    let { name, profile } = userObj;
    localStorage.setItem(this.NAME, name);
    localStorage.setItem(this.PROFILE, profile);
  }

  /**
   * Get current logged user data from localStorage
   * @return {name: string, profile: string }
   */
  getCurrentUserData(): any {
    return {
      name: localStorage.getItem(this.NAME),
      profile: localStorage.getItem(this.PROFILE)
    };
  }

  /**
   * Set current user permissions
   * @param userObj object cointaining user permissions
   * @return void
   */
  setCurrentUserPermissions(permissionsObj: any): void {
    let { products, invoice, stock, charts, cmv, users, goal } = permissionsObj;
    localStorage.setItem(this.PRODUCTS, products);
    localStorage.setItem(this.INVOICE, invoice);
    localStorage.setItem(this.STOCK, stock);
    localStorage.setItem(this.CHARTS, charts);
    localStorage.setItem(this.CMV, cmv);
    localStorage.setItem(this.USERS, users);
  }

  /**
   * Get current logged user permissions from localStorage
   * @return {products: boolean, invoice: boolean, stock: boolean, charts: boolean, cmv: boolean, users: boolean }
   */
  getCurrentUserPermissions(): any {
    try {
      return {
        products: (localStorage.getItem(this.PRODUCTS).toUpperCase() == 'TRUE') ? true : false,
        invoice: (localStorage.getItem(this.INVOICE).toUpperCase() == 'TRUE') ? true : false,
        stock: (localStorage.getItem(this.STOCK).toUpperCase() == 'TRUE') ? true : false,
        charts: (localStorage.getItem(this.CHARTS).toUpperCase() == 'TRUE') ? true : false,
        cmv: (localStorage.getItem(this.CMV).toUpperCase() == 'TRUE') ? true : false,
        users: (localStorage.getItem(this.USERS).toUpperCase() == 'TRUE') ? true : false,
      };
    } catch {
      return "error"
    }
  }

  /**
   * Store current user token
   * @param token user's token
   * @return void
   */
  storeToken(token: string): void {
    localStorage.setItem(this.TOKEN, token);
  }

  /**
   * Get current user's token
   * @return current user's token
   */
  getToken(): string {
    return localStorage.getItem(this.TOKEN);
  }

  /**
   * Set current user's role
   * @param isAdmin admin or not
   * @return void
   */
  setRole(isAdmin: boolean): void {
    localStorage.setItem(this.IS_ADMIN, isAdmin.toString());
  }

  /**
   * Check if a user is admin
   * @return is admin or not
   */
  isAdmin(): boolean {
    if (!localStorage.getItem(this.IS_ADMIN)) return false;
    return (localStorage.getItem(this.IS_ADMIN).toUpperCase() == 'TRUE') ?
      true : false;
  }

  /**
   * Wipe all data from local storage
   * @return void
   */
  clearUserData(): void {
    localStorage.removeItem(this.NAME);
    localStorage.removeItem(this.PROFILE);
    localStorage.removeItem(this.ESTABLISHMENT);
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.IS_ADMIN);
    localStorage.removeItem('establishment_name');
  }
}
