import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNewProductComponent } from '../../components/modals/modal-new-product/modal-new-product.component';
import { ProductService } from '../../service/product.service';
import { AlertService } from '../../service/alert.service'
import { GroupsService } from '../../service/groups.service';
import { ConstantsService } from '../../service/constants.service';
import { StorageService } from '../../service/storage.service';
import { Product } from '../../model/product';
import { GroupEstablishment } from '../../model/group';
import { MeasurementUnit } from '../../model/constants';
import { ModalSeeProductsComponent } from '../../components/modals/modal-see-products/modal-see-products.component';
import { DataSource } from '@angular/cdk/table';
import { ModalEditProductComponent } from '../../components/modals/modal-edit-product/modal-edit-product.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class ProductsComponent {

  constructor(
    private modalService: MDBModalService,
    private productService: ProductService,
    private groupService: GroupsService,
    private constantsService: ConstantsService,
    private storageService: StorageService,
    private alertService: AlertService
  ) { }

  modalRef: MDBModalRef;

  displayedColumns: string[] = ['name', 'group', 'measurementUnity', 'date', 'status', 'option'];
  data_source = new MatTableDataSource<Product>();
  products_get_response: any;

  /* pagination related variables */
  current_page = 1;
  max_page_size = environment.page_size;
  total_entries: number;

  groupList: GroupEstablishment[] = [];
  unitList: MeasurementUnit[];

  requests_loaded: number = 0;

  search: string;
  is_searching: boolean = false;

  csv_file;

  ngOnInit() {
    this._loadProdutcs("");

    this._loadGroupList();
    this._loadMeasurementUnits();
  }

  openModalNewProduct() {
    this.modalRef = this.modalService.show(ModalNewProductComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        editting: false,
        groupList: this.groupList,
        unitList: this.unitList
      }
    });

    let establishmentId = this.storageService.getCurrentEstablishment();
    this.modalRef.content.action.subscribe(
      (res) => {
        if (res) {
          res.list.forEach(element => {
            this.productService.createProduct(element, establishmentId).subscribe(
              (data) => {
                this._loadProdutcs("");
              },
              (err) => {
                console.error(err);
              }
            );
          });
        }
      }
    );
  }

  openModalEditProduct(id: number) {
    let p = this.data_source.data.find(x => x.id == id);

    this.modalRef = this.modalService.show(ModalEditProductComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        'productForm': {
          'name': p.name,
          'groupId': p.groupId,
          'measurementUnity': p.measurementUnity
        },
        editting: true,
        groupList: this.groupList,
        unitList: this.unitList,
      }
    });

    this.modalRef.content.action.subscribe(
      (res) => {
        if (res) {
          this.productService.editProduct({
            'id': id,
            'name': res.name,
            'groupId': res.groupId,
            'measurementUnity': res.measurementUnity
          }).subscribe(
            (data) => {
              this.requests_loaded = 0;
              this._updateDataSource({
                'id': id,
                'name': res.name,
                'group': res.group,
                'groupId': res.groupId,
                'measurementUnity': res.measurementUnity.name,
                'action': 'update'
              });
              this._loadProdutcs("");
              this._loadGroupList();
              this._loadMeasurementUnits();
            },
            (err) => {
              console.error(err);
            }
          );
        }
      }
    );
  }

  /* Change to the next page */
  nextPage() {
    if (this.products_get_response.next != null) {
      if (this.current_page * this.max_page_size < this.total_entries)
        this.current_page++;

      if (this.is_searching)
        this._loadProdutcs(this.search);
      else
        this._loadProdutcs("")
    }
  }


  /* Change to the previous page */
  prevPage() {
    if (this.products_get_response.previous != null) {
      this.current_page--;
      if (this.current_page < 0) this.current_page = 0;

      if (this.is_searching)
        this._loadProdutcs(this.search);
      else
        this._loadProdutcs("")
    }
  }

  /* Delete product */
  deleteProduct(id: number) {
    this.alertService.alertConfirmation('Deletando...', 'Deseja realmente deletar?').subscribe((res) => {
      if (res) {
        this._deleteProduct(id);
      }
    });
  }

  _deleteProduct(id: number) {
    this.productService.deleteProduct(id).subscribe(
      (data) => {
        this._updateDataSource({ 'id': id, 'action': 'delete' });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  changeStatus(status, user_id) {
    this.productService.editProductStatus(status, user_id).subscribe(data => {
    })
  }


  searchProducts() {
    if (this.search == "" || this.search == null) {
      this.is_searching = false;
      this._loadProdutcs("")
    }
    else {
      this.is_searching = true;
      this._loadProdutcs(this.search)
    }
  }


  private _loadProdutcs(search: string) {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this.productService.getProducts(this.current_page, establishmentId, search).subscribe((data) => {
      if (data) {
        this.requests_loaded += 1;
        this.products_get_response = data;
        this._updatePagination(data.count);
        if (this.modalRef) this.modalRef.hide();

        let productArr = [] as Product[];
        for (let d of data.results) {
          let p = {} as Product;
          p.id = d.id;
          p.name = d.name;
          p.group = d.group;
          p.groupId = d.group.id;
          p.measurementUnity = d.measurement_unit;
          p.status = d.status;
          p.date = d.created_at;
          productArr.push(p);
        }

        this.data_source.data = productArr;

      }
    });
  }


  /**
   * Update material table data source
   * @param Object table columns
   * @return void
   */
  private _updateDataSource({
    id,
    name = '',
    group = null,
    groupId = null,
    measurementUnity = null,
    action = 'delete'
  }) {
    let el_index = this.data_source.data.findIndex((x) => (x.id == id));
    if (action == 'delete') {
      this.data_source.data.splice(el_index, 1);
      this.data_source = new MatTableDataSource<any>(this.data_source.data);
      this._updatePagination(this.total_entries - 1)
    }
    else {
      this.data_source.data[el_index].name = name;
      this.data_source.data[el_index].group = group;
      this.data_source.data[el_index].groupId = groupId;
      this.data_source.data[el_index].measurementUnity = measurementUnity;
    }
  }


  /**
   * Update pagination control
   * @param total_entries the number of entries to be shown
   * @return void
   */
  private _updatePagination(total_entries: number) {
    this.total_entries = total_entries;
  }

  private _loadGroupList() {
    let establishmentId = this.storageService.getCurrentEstablishment();

    this.groupService.getEstablishmentGroups(establishmentId).subscribe(data => {
      this.groupList = [];
      for (let index = 0; index < data.results.length; index++) {
        if (data.results[index].status == true) {
          this.groupList.push(data.results[index])
        }
      }
      this.requests_loaded += 1;
    });
  }

  private _loadMeasurementUnits() {
    this.constantsService.getConstants().subscribe(data => {
      this.unitList = data.measurement_units;
      this.requests_loaded += 1;
    })
  }

  changeCSV(event) {
    let establishmentId = this.storageService.getCurrentEstablishment();
    this.alertService.loaderSpinnerOpen();

    if (event.target.files && event.target.files[0]) {
      var target = event.target.files[0]
      this.productService.seeCSVFile(target, establishmentId).subscribe(data => {

        this.alertService.loaderSpinnerClose();
        this.modalRef = this.modalService.show(ModalSeeProductsComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: true,
          ignoreBackdropClick: false,
          class: 'modal-dialog-centered modal-dialog modal-lg',
          containerClass: '',
          animated: false,
          data: {
            source: data
          }
        });
        this.modalRef.content.action.subscribe((res) => {
          if (res == 'save') {
            this.alertService.loaderSpinnerOpen();
            this.productService.uploadCSVFile(target, establishmentId).subscribe(data => {
              this.alertService.loaderSpinnerClose();
              this._loadProdutcs("")
            })
          }
        });
        this.csv_file = null;

      }, error => {
        this.alertService.loaderSpinnerClose();
        this.alertService.alert(
          'Erro ao receber arquivo csv',
          "Por favor, verifique as informações do seu arquivo.",
          5000
        );
        this.csv_file = null;
      })
    }

  }

  csvExample() {
    let data = [{
      created_at: "2021-02-12T15:46:12",
      establishment: 31,
      group: { id: 32, name: "Lacticínios", default: true, created_at: "2020-10-01T19:41:55" },
      id: null,
      initial_price: 1.99,
      initial_quantity: 50,
      measurement_unit: { id: 1, name: "Kg" },
      name: "Produto X",
      status: true
    }]
    this.modalRef = this.modalService.show(ModalSeeProductsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog modal-lg',
      containerClass: '',
      animated: false,
      data: {
        source: data,
        is_example: true
      }
    });
  }

}
