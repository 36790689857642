import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})

export class ModalErrorComponent {

  constructor(public modalRef: MDBModalRef) { }

  text: string;

  ngOnInit() {
  }

}
