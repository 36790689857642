import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { User, Users } from '../model/user';
import { Md5 } from 'md5-typescript';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) { }

  /**
   * Get me(current user)
   * @return void
   */
  getUserMe(): Observable<Users> {
    return this.http.get<Users>(environment.path + "core/user/?user=me");
  }


  /**
   * Get a list of users bases on the current page
   * @param page current page
   * @param id estblishment's id
   * @param search search text
   * @return list of users
   */
  getUsers(id, page: number, search: string): Observable<Users> {
    let page_size = environment.page_size;
    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('search', search.toString())
        .set('page_size', page_size.toString())
    };

    if (id != "" && id != null) {
      options.params = options.params.set('establishment', id)
    }

    return this.http.get<Users>(`${environment.path}core/user/`, options);
  }


  /**
   * Get user by id
   * @param id user's id
   * @return user object
   */
  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${environment.path}core/user/${id}`);
  }


  /**
   * Delete user
   * @param id user's id
   * @return void
   */
  deleteUser(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.path}core/user/${id}`);
  }


  /**
   * Create new user
   * @param user's param
   * @return just for error tracking
   */
  createUser(user_obj: any): Observable<any> {
    for (let user_param_value of Object.values(user_obj)) {
      if (user_param_value == undefined)
        return throwError(null);
    }
    const { name, email, password, profile, status, establishment } = user_obj;


    const body = new HttpParams()
      .set(`name`, name)
      .set('email', email)
      .set('password', Md5.init(password).toUpperCase())
      .set('profile', profile)
      .set('status', status)
      .set('establishment', establishment);

    return this.http.post<any>(environment.path + "core/user/", body.toString());
  }


  /**
   * Update user by id
   * @param user's param
   * @return just for error tracking
   */
  editUser(user_obj: any): Observable<void> {
    for (let user_param_value of Object.values(user_obj)) {
      if (user_param_value == undefined)
        return throwError(null);
    }
    const { id, name, email, profile, status } = user_obj;

    const body = new HttpParams()
      .set('name', name)
      .set('email', email)
      .set('profile', profile)
      .set('status', status);

    return this.http.patch<void>(`${environment.path}core/user/${id}/`, body.toString());
  }

  /**
   * Patch user status
   * @param status and user_id
   * @return user
   */
  editUserStatus(status: boolean, user_id: number): Observable<User> {
    const body = new HttpParams()
      .set(`status`, status.toString())

    return this.http.patch<User>(`${environment.path}core/user/${user_id}/`, body.toString());
  }


  addUserEstablishment(establishment, user) {
    const body = new HttpParams()
      .set(`user`, user)
      .set('establishment', establishment)

    return this.http.post<any>(environment.path + "core/user-establishment/", body.toString());
  }

}
