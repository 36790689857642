import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './service/auth.service';
import { StorageService } from './service/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //check if it's home page access
    if (route.routeConfig.path === '') {
      if (this.authService.isLogged()) {
        if (this.authService.isAdmin()) {
          this.router.navigate(['/main/establishments']);
        }
        else {
          this.router.navigate(['/main/home']);
        }
      }
      return true;
    }
    else {//not homepage
      if (route.data.roles) {//check for admin permissions
        if (this.authService.isLogged()) {
          if (this.authService.isAdmin()) {
            return true;
          }
          this.router.navigateByUrl('/');
          return false;
        }
        this.router.navigateByUrl('/');
        return false;
      }
      else if (route.data.page != null) { //check if needs a page permission
        let path = route.routeConfig.path
        let permissions = this.storageService.getCurrentUserPermissions()

        if (path == "products" && permissions.products) return true
        if (path == "invoice-entry" && permissions.invoice) return true
        if (path == "low-stock" && permissions.stock) return true
        if (path == "purchase-suggestion") return true
        if (path == "graphics-shopping" && permissions.charts) return true
        if (path == "goals" && permissions.charts) return true
        if (path == "users" && permissions.users) return true
        if (path == "cost-center" && permissions.users) return true
        if (path == "cmv-management" && permissions.cmv) return true
        return false;
      }
      else {//check for normal user permissions
        if (this.authService.isLogged()) return true;

        this.router.navigateByUrl('/');
        return false;
      }
    }
  }

}
